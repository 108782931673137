import React from "react";
import NavigationBar from "../../components/ui/Navbar";
import Footer from "../../components/ui/Footer";

const Refund = () => {
  return (
    <div className="flex flex-col items-center bg-[#f6f6f6]">
      <NavigationBar />
      <div className="container lg:px-24 px-4 py-20 md:py-32 text-justify">
        <p className="mt-1">Last Updated on September 5th, 2024</p>
        <p className="self-center whitespace-nowrap text-2xl font-semibold mt-5">
          Cancellation and Refund Policy
        </p>
        <p className="mt-3">
          This policy outlines the cancellation and refund terms applicable to
          services offered by Zzeeh Productions Pvt Ltd ("Zzeeh") through our
          website, zzeeh.com (the "Website"). Please read this policy carefully
          before making any bookings or service agreements with us.
        </p>

        <p className="mt-7 font-semibold">Event Cancellation:</p>
        <p>
          You may cancel your booking for event services up to 30 days before
          the scheduled event date for a full refund. Cancellations made less
          than 30 days before the event may incur a cancellation fee or be
          non-refundable, depending on the specific terms agreed upon during the
          booking process.
        </p>

        <p className="mt-7 font-semibold">Cancellation by Zzeeh:</p>
        <p>
          In the unlikely event that Zzeeh has to cancel your booking due to
          unforeseen circumstances (e.g., force majeure or issues beyond our
          control), you will be entitled to a full refund of any payments made.
          We will work with you to reschedule your event whenever possible.
        </p>

        <p className="mt-7 text-2xl font-semibold">Refund Policy</p>
        <p>
          Our refund policy governs all payments made for event bookings, decor
          services, and planning engagements through our Website. By booking
          services with us, you agree to the terms outlined here.
        </p>

        <p className="mt-7 font-semibold">Refund Processing Time</p>
        <p>
          Refunds for eligible cancellations will be processed within 7-10
          business days from the date of cancellation confirmation. Refunds will
          be made to the original payment method used during booking.
        </p>

        <p className="mt-7 font-semibold">Non-Refundable Items:</p>
        <p>
          Deposits paid for securing specific dates or custom event elements
          (such as bespoke decor, specialized vendors, etc.) are non-refundable
          unless otherwise agreed upon in writing. Additionally, last-minute
          cancellations (within 7 days of the event) are generally
          non-refundable due to the preparation and resources allocated for your
          event.
        </p>

        <p className="mt-7">
          Please note that the cancellation and refund policies above apply
          specifically to services offered by Zzeeh Productions Pvt Ltd through
          our Website. For any third-party vendor services engaged during the
          planning process, their individual cancellation and refund policies
          may apply.
        </p>

        <p className="mt-7">
          Our team is committed to ensuring your satisfaction and creating
          memorable events. If you have any questions or require clarification
          regarding our cancellation and refund policy, please reach out to our
          support team at info@zzeeh.com. We are here to assist you.
        </p>

        <p className="mt-7">
          By proceeding with a booking on our Website, you acknowledge and
          accept the terms outlined in this cancellation and refund policy.
        </p>

        <p className="mt-7">
          Thank you for choosing Zzeeh Productions Pvt Ltd for your event
          planning and decor needs. We look forward to making your special
          moments unforgettable.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
