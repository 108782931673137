import React from "react";

const DynamicList = ({
  listType,
  data,
  newLine,
  noColon,
}: {
  listType: string;
  data: Array<Record<string, string>>;
  newLine?: boolean;
  noColon?: boolean;
}) => {
  if (newLine) {
    return (
      <ul
        className={`${listType} ${
          listType === "" ? "" : "ml-5 px-5"
        } py-2 space-y-2`}
      >
        {data.map((point, index) => (
          <li key={index}>
            <p className="font-semibold mb-1">{point.header}</p>
            <span
              dangerouslySetInnerHTML={{
                __html: point.body.replace(
                  "Zzeeh Weddings",
                  '<a href="/zzeeh_weddings" class="text-blue-500 hover:underline">Zzeeh Weddings</a>'
                ),
              }}
            ></span>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={`${listType} ml-5 px-5 py-2 space-y-1`}>
      {data.map((point, index) => (
        <li key={index}>
          <span className="font-semibold">
            {point.header}
            {noColon ? "" : ":"}{" "}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: point.body.replace(
                "Zzeeh Weddings",
                '<a href="/zzeeh_weddings" class="text-blue-500 hover:underline">Zzeeh Weddings</a>'
              ),
            }}
          ></span>
        </li>
      ))}
    </ul>
  );
};

export default DynamicList;
