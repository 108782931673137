import React from "react";
import CTAButton from "../ui/CTAButton";
import dawn from "../../assets/images/ui/gold-dawn.webp";
import { Pendant } from "../../assets/icons/Icons";

const WhyChooseUs = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <section
      id="why-choose-us"
      className="relative px-4 py-10 lg:p-10 container"
    >
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>

      <div className="flex flex-col items-center text-center">
        <div className="space-y-2 lg:space-y-4">
          <div className="flex flex-row items-center justify-center space-x-4 mb-6">
            <div className="rotate-180">
              <Pendant />
            </div>
            <p className="text-pureGold text-sm lg:text-base font-medium">
              WHY CHOOSE US?
            </p>
            <Pendant />
          </div>
          <p className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage md:w-[520px]">
            Every love story deserves a celebration as{" "}
            <i className="text-gold font-gt-super">unique </i>as you!
          </p>
        </div>

        <div className="flex flex-col space-y-5 my-10">
          <p className="text-purple text-sm lg:text-lg xl:text-xl md:w-[600px]">
            Our passion lies in crafting extraordinary weddings that reflect
            your personal style and exceed your expectations.
          </p>
          <p className="text-purple text-sm lg:text-lg xl:text-xl md:w-[600px]">
            With meticulous attention to detail and a deep understanding of your
            vision, we create unforgettable experiences that you and your loved
            ones will cherish for a lifetime.
          </p>
          <p className="text-purple text-sm lg:text-lg xl:text-xl md:w-[600px]">
            From intimate ceremonies to grand receptions, our dedicated team is
            committed to turning your dream wedding into a reality.
          </p>
        </div>

        <CTAButton
          name={`Book Your Dream Wedding`}
          handleClick={() => setVisible(true)}
        />
      </div>
    </section>
  );
};

export default WhyChooseUs;
