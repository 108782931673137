import React from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";

const Stats = ({
  Icon,
  value,
  name,
}: {
  Icon: () => JSX.Element;
  value: number;
  name: string;
}) => {
  return (
    <div className="flex flex-col items-center bg-white border-[1px] border-darkGold rounded-3xl w-52 px-7 py-6">
      <Icon />

      <ReactVisibilitySensor partialVisibility offset={{ bottom: 0 }}>
        {({ isVisible }: { isVisible: boolean }) => (
          <div className="mt-4 h-14 w-24 flex justify-center items-center">
            {isVisible ? (
              <p className="text-gold text-4xl font-gt-super">
                <CountUp
                  duration={2}
                  className="counter"
                  start={0}
                  end={value}
                />
                <span>+</span>
              </p>
            ) : (
              <p className="text-gold text-4xl font-gt-super">
                <span>{value}</span>
                <span>+</span>
              </p>
            )}
          </div>
        )}
      </ReactVisibilitySensor>

      <p className="mt-2 text-purple md:text-xl font-made-mirage font-medium">
        {name}
      </p>
    </div>
  );
};

export default Stats;
