import React, { useEffect, useState } from "react";
import { Navbar } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logos/zzeeh_logo2.webp";
import whatsapp_logo from "../../assets/images/logos/zzeeh.jpg";
import { navItems } from "../../assets/data/navbar";
import CTAButton from "./CTAButton";
import FormModalOne from "../MainPage/FormModalOne";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setCurrentNav(path);
  }, [location]);

  return (
    <>
      <Navbar
        fluid
        rounded
        id="NavMain"
        className="fixed top-0 w-full z-50 container"
      >
        <Navbar.Brand
          href="/"
          onClick={() => {
            navigate(`/`);
            setCurrentNav("");
            var elementToView = document.getElementById("home");
            elementToView?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <img src={logo} className="ml-3 h-9 cursor-pointer" alt="Zzeeh" />
          {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Zzeeh Weddings & Event Planners
        </span> */}
        </Navbar.Brand>

        <div className="flex md:order-2">
          <CTAButton
            name="Schedule Free Consultation"
            handleClick={() => setModalVisible(true)}
          />
          <Navbar.Toggle className="ml-3 hover:bg-white" />
        </div>

        <Navbar.Collapse>
          {navItems.map((item, index) => (
            <Navbar.Link
              key={index}
              href={item.route === "" ? "/" : `/${item.route}`}
              active={currentNav === item.route}
              onClick={() => setCurrentNav(item.route)}
              className={`lg:text-lg font-medium cursor-pointer md:hover:!text-[gold] bg-white ${
                currentNav === item.route ? "!text-gold" : "!text-dark"
              }`}
            >
              {item.name}
            </Navbar.Link>
          ))}
        </Navbar.Collapse>
      </Navbar>

      <FormModalOne
        modalIsOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      />

      <FloatingWhatsApp
        phoneNumber="9886400292"
        accountName="Zzeeh Productions"
        statusMessage="Online"
        avatar={whatsapp_logo}
        chatMessage="Discover the magic of effortless planning with Zzeeh Productions!"
        messageDelay={1}
        allowEsc
        allowClickAway
        notification
      />
    </>
  );
};

export default NavigationBar;
