import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction to Zzeeh Weddings",
    },
    {
      id: "services-offered",
      label: "Services Offered by Zzeeh Weddings",
    },
    {
      id: "unique-features",
      label: "Unique Features of Zzeeh Weddings’ Décor",
    },
    {
      id: "types-of-weddings",
      label: "Types of Weddings Catered by Zzeeh Weddings",
    },
    {
      id: "design-process",
      label: "The Design Process at Zzeeh Weddings",
    },
    {
      id: "real-weddings",
      label: "Real Weddings: A Showcase of Zzeeh Weddings’ Creations",
    },
    {
      id: "popular-venues",
      label: "Popular Wedding Venues in Bangalore Decorated by Zzeeh Weddings",
    },
    {
      id: "working-with-vendors",
      label: "How Zzeeh Weddings Works with Wedding Planners and Vendors",
    },
    {
      id: "budget-pricing",
      label: "Budget and Pricing for Wedding Decoration Services",
    },
    {
      id: "booking-process",
      label: "Booking Zzeeh Weddings for Your Wedding",
    },
    {
      id: "why-top-choice",
      label: "Why Zzeeh Weddings is a Top Choice for Bangalore Weddings",
    },
    {
      id: "faqs",
      label: "Frequently Asked Questions (FAQs)",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Wedding Decorators in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/jV3iKj4ilNs?si=1icG-5O3Dv6lUDX7"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        <i className="text-xl font-made-mirage font-medium">
          Transforming Dreams into Beautiful Wedding Venues
        </i>
      </p>
      <p className="!mt-5">
        Weddings are a time of celebration, a once-in-a-lifetime event filled
        with love, traditions, and beauty. For many, the decor is the heart of
        the wedding, as it sets the mood, reflects personal tastes, and creates
        lasting memories. In a city like Bangalore, where tradition meets
        modernity, one name stands out among wedding decorators – Zzeeh
        Weddings.
      </p>
      <p className="!mt-3">
        <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
          Zzeeh Weddings
        </a>{" "}
        is a trusted and experienced wedding decorator in Bangalore, known for
        curating stunning and personalized wedding setups that cater to both
        traditional and contemporary styles. If you're looking to make your
        wedding a grand event, this is your go-to guide to understanding why
        Zzeeh Weddings could be the perfect choice for your big day.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Zzeeh Weddings
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          is more than just a wedding decorator; they are artisans who transform
          spaces into visual masterpieces. With over a decade of experience in
          the wedding industry, Zzeeh Weddings has earned a reputation for
          delivering bespoke, awe-inspiring wedding decor across Bangalore. From
          luxurious palaces to intimate garden weddings, they create stunning
          environments that leave guests in awe.
        </p>
      </section>

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Zzeeh Weddings
        </h1>
        <p>
          Whether you're planning a traditional South Indian wedding or a modern
          fusion event,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has a broad portfolio of services. Their offerings include complete
          wedding decor solutions, theme-based decor, and even customized
          concepts to match your unique style. From mandap decoration to
          lighting and drapery, every detail is taken care of with precision.
        </p>
      </section>

      <section id="unique-features" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Unique Features of Zzeeh Weddings’ Décor
        </h1>
        <p>
          One of the hallmarks of{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          is their fusion of traditional aesthetics with modern design
          sensibilities. They also stand out for their sustainable décor
          options, using eco-friendly materials whenever possible. Every wedding
          reflects the couple's personality, thanks to their tailor-made
          approach.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/indian-wedding-haldi-ceremony.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="types-of-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Types of Weddings Catered by Zzeeh Weddings
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          specializes in a variety of wedding formats. They handle traditional
          South Indian weddings with intricate detailing, as well as North
          Indian and multicultural celebrations. Whether it's an intimate family
          gathering or a grand affair, they ensure that every wedding is
          executed flawlessly.
        </p>
      </section>

      <section id="design-process" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Design Process at Zzeeh Weddings
        </h1>
        <p>
          Their design process begins with an initial consultation where they
          understand the client's vision and preferences. After venue visits and
          brainstorming sessions, a mock-up of the concept is created for client
          approval. On the wedding day, their team ensures smooth execution so
          that you can focus on enjoying your special moments.
        </p>
      </section>

      <section id="real-weddings" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Real Weddings: A Showcase of Zzeeh Weddings’ Creations
        </h3>
        <p>
          Get inspired by some of the real weddings{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has been a part of. From a grand traditional wedding to a chic garden
          ceremony, the testimonials and stories of happy couples give a glimpse
          into their expertise.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-indian-wedding-planner.webp"
        alt="budget-decor-ideas"
      />

      <section id="popular-venues" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Popular Wedding Venues in Bangalore Decorated by Zzeeh Weddings
        </h3>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has worked with some of the finest wedding venues in Bangalore,
          including luxury hotels, heritage sites, and stunning outdoor
          locations. Their expertise ensures that the venue is transformed into
          a breathtaking setting, perfect for the big day.
        </p>
      </section>

      <section id="working-with-vendors" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Zzeeh Weddings Works with Wedding Planners and Vendors
        </h1>
        <p>
          Coordination is key to the success of any wedding, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          excels in working closely with wedding planners and vendors. Their
          collaborative approach ensures that the entire team works in sync to
          bring the vision to life.
        </p>
      </section>

      <section id="budget-pricing" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget and Pricing for Wedding Decoration Services
        </h1>
        <p>
          Wedding decoration costs vary based on multiple factors, including
          venue size, the complexity of the theme, and materials used. Zzeeh
          Weddings offers flexible pricing, ensuring that they can work within
          your budget while delivering high-quality décor.
        </p>
      </section>

      <section id="booking-process" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Booking Zzeeh Weddings for Your Wedding
        </h1>
        <p>
          Booking{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          is a seamless process. They provide consultations where you can
          discuss your ideas, finalize the budget, and get an overview of how
          they will transform your venue. Early bookings are recommended to
          secure their services for your preferred date.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/impressive-backdrop-decoration.webp"
        alt="success-stories"
      />

      <section id="why-top-choice" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings is a Top Choice for Bangalore Weddings
        </h1>
        <p>
          Their commitment to quality, attention to detail, and understanding of
          Bangalore’s wedding scene make them a preferred decorator. From small,
          intimate gatherings to grand celebrations,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          consistently delivers on their promise of creating dream weddings.
        </p>
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQs)
        </h1>
        <p>
          Got questions?{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has compiled some of the most frequently asked queries, ensuring that
          clients have all the information they need. Whether you’re wondering
          about customization options or out-of-town weddings, their team is
          ready to assist.
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          stands as one of the top wedding decorators in Bangalore, offering a
          blend of tradition, modernity, and personalized attention to every
          wedding they touch. Their ability to create magical, one-of-a-kind
          wedding settings ensures that your big day will be remembered for
          years to come.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
