import React from "react";
import { GoldDawn, Pendant } from "../../assets/icons/Icons";
import weddings from "../../assets/images/gallery/weddings.webp";
import CTAButton from "../ui/CTAButton";

const Hero = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const delay = 300;

  return (
    <section id="hero" className="pb-10 px-4 container">
      <div className="flex flex-col md:flex-row justify-between items-center md:space-x-14 mt-32">
        <div
          data-aos="zoom-out"
          data-aos-delay={delay}
          className="flex flex-col mb-10 md:mb-0 md:w-1/2"
        >
          <div className="flex flex-row items-center space-x-4 mb-6">
            <p className="text-pureGold text-sm lg:text-xl font-gt-super">
              WEDDINGS BY ZZEEH
            </p>
            <Pendant />
          </div>
          <div className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage">
            <p>
              Your dream <i className="text-gold font-gt-super">Wedding, </i>
            </p>
            <p className="lg:mt-3">Our Expertise.</p>
          </div>
          <p className="text-purple text-sm lg:text-xl lg:w-[480px] mt-4">
            From intimate ceremonies to grand celebrations, we turn your vision
            into a breathtaking reality.
          </p>
          <div className="flex flex-row items-center space-x-3 lg:text-lg font-made-mirage font-medium my-8">
            <p>Planning</p>
            <GoldDawn />
            <p>Decoration</p>
            <GoldDawn />
            <p>Photography</p>
          </div>
          <div>
            <CTAButton
              name="Start Planning Your Wedding"
              handleClick={() => setVisible(true)}
            />
          </div>
        </div>
        <div className="relative md:w-1/2">
          <div
            data-aos="fade-right"
            data-aos-delay={delay}
            className="absolute inset-y-0 left-0 w-60 bg-gradient-to-r from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"
          ></div>
          <img
            data-aos="fade-right"
            data-aos-delay={200}
            src={weddings}
            alt="Weddings"
            className="lg:h-[360px] lg:w-[580px] rounded-3xl"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
