import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { customModal } from "../../assets/styles/modal";

const VideoModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  const refVideo = useRef(null);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <Modal
      size={"3xl"}
      show={modalIsOpen}
      onClose={closeModal}
      ref={refVideo}
      theme={customModal}
    >
      <Modal.Header>Exquisite Luxury Wedding✨</Modal.Header>
      <Modal.Body className="d-flex justify-content-center w-full">
        <iframe
          width="100%"
          height={isMobileScreen ? "200" : "400"}
          src="https://www.youtube.com/embed/jV3iKj4ilNs?si=Frko--9v_XV8qssI"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
