import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "bangalore-budget-wedding",
      label: "Why Bangalore is Ideal for a Budget Wedding",
    },
    {
      id: "services-budget-events",
      label: "Services Offered by Zzeeh Weddings for Budget-Friendly Events",
    },
    {
      id: "top-venues-bangalore",
      label:
        "Top Budget Wedding Venues in Bangalore Recommended by Zzeeh Weddings",
    },
    { id: "budget-decor-ideas", label: "Budget Wedding Decor Ideas" },
    {
      id: "save-on-costs",
      label: "How Zzeeh Weddings Helps You Save on Wedding Costs",
    },
    {
      id: "success-stories",
      label:
        "Real-Life Success Stories of Budget Weddings Planned by Zzeeh Weddings",
    },
    {
      id: "budget-tips",
      label: "Tips from Zzeeh Weddings for Budget Brides and Grooms",
    },
    {
      id: "common-mistakes",
      label:
        "Common Budget Wedding Mistakes and How Zzeeh Weddings Avoids Them",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Diverse Venue Options",
      body: "From luxury hotels to garden venues, the variety helps in choosing what fits your budget.",
    },
    {
      header: "Competitive Vendor Rates",
      body: "The large number of wedding vendors in Bangalore helps keep prices competitive.",
    },
    {
      header: "Cultural Significance",
      body: "With a deep-rooted tradition of vibrant weddings, Bangalore offers access to rituals and customs at cost-effective rates.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection & Booking",
      body: "Zzeeh helps identify and book venues that are not only beautiful but also affordable.",
    },
    {
      header: "Decor & Styling on a Budget",
      body: "Their decor specialists create stunning settings using cost-effective materials, repurposed decor, and minimalistic themes.",
    },
    {
      header: "Vendor Management",
      body: "From catering to photography, Zzeeh partners with budget-friendly vendors who deliver high-quality services.",
    },
    {
      header: "Entertainment & Guest Management",
      body: "They ensure smooth coordination of entertainment options, from DJs to traditional bands, at a minimal cost.",
    },
    {
      header: "Transportation & Logistics",
      body: "Managing logistics such as guest transport, bridal entry, and family coordination is handled with precision.",
    },
  ];

  const list3 = [
    {
      header: "Outdoor Venues",
      body: "Affordable garden venues for intimate celebrations.",
    },
    {
      header: "Indoor Venues",
      body: "Budget-friendly banquet halls with flexible packages.",
    },
    {
      header: "Destination Wedding Venues",
      body: "Explore resorts near Bangalore for a beautiful yet affordable destination wedding.",
    },
  ];

  const list4 = [
    {
      header: "Minimalistic Themes",
      body: "Simple, clean decor that focuses on elegance.",
    },
    {
      header: "DIY Decorations",
      body: "Incorporating handmade elements to add a personal touch.",
    },
    {
      header: "Floral Arrangements",
      body: "Opting for local flowers instead of exotic ones to reduce costs.",
    },
    {
      header: "Lighting Solutions",
      body: "Using creative lighting techniques to transform spaces.",
    },
  ];

  const list5 = [
    {
      header: "Vendor Negotiations",
      body: "With years of experience and relationships, Zzeeh can negotiate better rates with vendors.",
    },
    {
      header: "Efficient Budget Allocation",
      body: "They help you prioritize your budget to ensure key elements are taken care of first.",
    },
    {
      header: "Managing Guest Lists",
      body: "By keeping the guest list tight and managing RSVPs, Zzeeh helps minimize costs on catering and seating arrangements.",
    },
    {
      header: "Use of Technology",
      body: "Digital solutions such as online invitations and budget tracking tools also help save money.",
    },
  ];

  const list6 = [
    {
      header: "Elegant Garden Wedding",
      body: "A couple on a tight budget had a magical wedding in a garden venue with eco-friendly decor.",
    },
    {
      header: "Traditional Temple Wedding",
      body: "Zzeeh Weddings organized a simple yet beautiful temple wedding with a minimal guest list.",
    },
    {
      header: "Destination Resort Wedding",
      body: "A small gathering at a resort near Bangalore, offering the feel of a grand wedding without the hefty price tag.",
    },
  ];

  const list7 = [
    {
      header: "Prioritize Expenses",
      body: "Identify what’s most important to you—be it decor, food, or photography—and allocate your budget accordingly.",
    },
    {
      header: "DIY Projects",
      body: "Add personal touches through DIY decor or invites.",
    },
    {
      header: "Sustainable Practices",
      body: "Eco-friendly weddings can also be cost-effective.",
    },
    {
      header: "Choose the Right Date",
      body: "Off-season weddings tend to be more affordable.",
    },
  ];

  const list8 = [
    {
      header: "Over-Inviting Guests",
      body: "Zzeeh Weddings ensures your guest list remains realistic and manageable.",
    },
    {
      header: "Last-Minute Bookings",
      body: "Booking vendors early helps avoid price hikes.",
    },
    {
      header: "Splurging on Decor",
      body: "They focus on cost-effective yet beautiful design solutions.",
    },
    {
      header: "Ignoring Hidden Costs",
      body: "Zzeeh carefully tracks all potential costs, including taxes and service charges, ensuring no surprises.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Wedding Planner in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/OYWfA-3v0Cw?si=wgp6V0tpOw7oC_Cm"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Weddings are one of the most significant events in a couple's life,
          and every couple wants their day to be memorable. But weddings can
          also be expensive, and it’s easy for costs to spiral out of control.
          This is where{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          comes in—a wedding planner in Bangalore that specializes in curating
          beautiful weddings without going over budget. Whether it’s finding the
          perfect venue or managing vendors, Zzeeh Weddings has mastered the art
          of making dream weddings affordable.
        </p>
      </section>

      <section id="bangalore-budget-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Bangalore is Ideal for a Budget Wedding
        </h1>
        <p>
          Bangalore, with its blend of modern infrastructure and rich cultural
          heritage, offers a variety of affordable wedding options. Whether
          you’re looking for a traditional wedding with cultural nuances or a
          contemporary celebration, the city has venues and vendors that fit any
          budget.
        </p>
        <div>
          <p>
            Some reasons why Bangalore is a great choice for budget weddings
            include:
          </p>
          <DynamicList listType="list-disc" data={list1} />
        </div>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/destination-wedding-packages-with-prices.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="services-budget-events" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Zzeeh Weddings for Budget-Friendly Events
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          provides a full range of wedding planning services, tailored to fit
          your budget. Some of their core services include:
        </p>
        <DynamicList listType="list-decimal" data={list2} />
      </section>

      <section id="top-venues-bangalore" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Budget Wedding Venues in Bangalore Recommended by Zzeeh Weddings
        </h1>
        <DynamicList listType="list-decimal" data={list3} />
      </section>

      <section id="budget-decor-ideas" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget Wedding Decor Ideas
        </h1>
        <p>
          One of the biggest challenges for budget weddings is decor, but{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          excels at creating breathtaking settings without excessive spending.
          Here are a few ideas:
        </p>
        <DynamicList listType="list-disc" data={list4} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/flower-mandap-decoration.webp"
        alt="budget-decor-ideas"
      />

      <section id="save-on-costs" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Zzeeh Weddings Helps You Save on Wedding Costs
        </h3>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          ensures that no stone is left unturned when it comes to saving on
          costs. Some of the ways they do this include:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <section id="success-stories" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Real-Life Success Stories of Budget Weddings Planned by Zzeeh Weddings
        </h3>
        <DynamicList listType="list-decimal" data={list6} />
      </section>

      <section id="budget-tips" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tips from Zzeeh Weddings for Budget Brides and Grooms
        </h1>
        <DynamicList listType="list-disc" data={list7} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/wedding-ceremony-decorations.webp"
        alt="success-stories"
      />

      <section id="common-mistakes" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Common Budget Wedding Mistakes and How Zzeeh Weddings Avoids Them
        </h1>
        <DynamicList listType="list-disc" data={list8} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          A wedding doesn't have to be expensive to be memorable. With careful
          planning and expert guidance from{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          , you can have a beautiful, unforgettable wedding in Bangalore without
          exceeding your budget. Their ability to curate budget-friendly
          weddings, while still maintaining quality and elegance, makes them a
          perfect choice for couples looking to plan their dream day. Reach out
          to Zzeeh Weddings today and start your journey towards a stunning yet
          affordable wedding!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
