export const navItems = [
  { name: "Home", route: "" },
  { name: "Weddings", route: "zzeeh_weddings" },
  { name: "About Us", route: "about_us" },
  { name: "Blogs", route: "blogs" },
  { name: "Contact Us", route: "contact_us" },
  // "events",
  // "rentals",
  // "gallery",
];
