import React, { useState } from "react";
import why from "../../assets/images/ui/why.webp";
import { whyChooseUs } from "../../assets/data/whyChooseUs";
import CTAButton from "../ui/CTAButton";
import dawn from "../../assets/images/ui/gold-dawn.webp";
import VideoModal from "./VideoModal";

const WhyChooseUs = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [videoModalShow, setVideoModalShow] = useState(false);

  return (
    <section
      id="why-choose-us"
      className="relative px-4 py-10 lg:p-10 container"
    >
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <img src={dawn} alt="dawn" className="hidden lg:flex" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-14">
        <div className="flex justify-center">
          <img
            data-aos="zoom-in"
            data-aos-delay={200}
            src={why}
            alt="Elegant Wedding"
            className="cursor-pointer"
            onClick={() => setVideoModalShow(true)}
          />
        </div>
        <div>
          <div className="space-y-2 lg:space-y-4">
            <p className="text-gold text-sm lg:text-base font-medium">
              WHY CHOOSE US?
            </p>
            <p className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage xl:w-[520px]">
              Premium experiences, tailored for your{" "}
              <i className="text-gold font-gt-super">Budget</i>
            </p>
            <p className="text-purple text-sm lg:text-lg xl:text-xl xl:w-[480px]">
              We go above and beyond to make every occasion most joyous, without
              breaking the bank.
            </p>
          </div>
          <div className="my-5 space-y-3 lg:space-y-6 lg:my-10">
            {whyChooseUs.map((item, index) => (
              <div key={index} className="flex flex-row items-center space-x-4">
                <item.icon />
                <p
                  data-aos="fade-right"
                  data-aos-delay={200 * index}
                  className="lg:text-lg text-dark"
                >
                  {item.point}
                </p>
              </div>
            ))}
          </div>

          <CTAButton
            name={`Start Planning Your Wedding`}
            handleClick={() => setVisible(true)}
          />
          <VideoModal
            modalIsOpen={videoModalShow}
            closeModal={() => setVideoModalShow(false)}
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
