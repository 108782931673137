export const GoldStar = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9.99287C16.077 10.3493 13.8231 10.6771 12.4394 11.7605C10.8131 13.0292 10.428 15.3528 10 20C9.55777 15.1818 9.15835 12.8724 7.37518 11.6322C5.99144 10.6629 3.75178 10.3493 0 10.0071C3.9087 9.65075 6.17689 9.32288 7.54636 8.25374C9.18688 6.97078 9.57204 4.66144 10 0C10.3994 4.29081 10.7561 6.58589 12.0827 7.91162C13.4094 9.23735 15.7204 9.60798 20 9.99287Z"
      fill="#E88B00"
    />
  </svg>
);

export const BlackStar = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7.9943C12.8616 8.2794 11.0585 8.5417 9.9515 9.40841C8.6505 10.4234 8.34237 12.2823 8 16C7.64622 12.1454 7.32668 10.2979 5.90014 9.30577C4.79315 8.53029 3.00143 8.2794 0 8.0057C3.12696 7.7206 4.94151 7.4583 6.03709 6.60299C7.3495 5.57662 7.65763 3.72915 8 0C8.31954 3.43264 8.60485 5.26871 9.66619 6.32929C10.7275 7.38988 12.5763 7.68639 16 7.9943Z"
      fill="#1E1A1E"
    />
  </svg>
);

export const YellowStar = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 19.9857C32.1541 20.6985 27.6462 21.3542 24.8787 23.521C21.6262 26.0584 20.8559 30.7056 20 40C19.1155 30.3635 18.3167 25.7448 14.7504 23.2644C11.9829 21.3257 7.50357 20.6985 0 20.0143C7.8174 19.3015 12.3538 18.6458 15.0927 16.5075C18.3738 13.9416 19.1441 9.32288 20 0C20.7989 8.58161 21.5121 13.1718 24.1655 15.8232C26.8188 18.4747 31.4408 19.216 40 19.9857Z"
      fill="#E88B00"
      fillOpacity="0.2"
    />
    <path
      d="M4.91126 20.014C6.66276 19.8041 8.20183 19.5714 9.55451 19.2846C12.0412 18.7572 13.9449 18.0379 15.4004 16.9016L15.4007 16.9013C17.1557 15.5289 18.2122 13.6204 18.9386 10.9174C19.3878 9.24597 19.7159 7.25039 20.0026 4.86103C20.2543 6.9438 20.5362 8.72537 20.901 10.2516C21.5216 12.8482 22.3936 14.7594 23.812 16.1769C25.2302 17.594 27.1494 18.4726 29.7515 19.0963C31.2642 19.459 33.0253 19.7393 35.0775 19.9863C33.3232 20.1966 31.7837 20.4302 30.4305 20.7191C27.9429 21.2503 26.0401 21.9769 24.5712 23.1268C22.8289 24.486 21.78 26.396 21.0576 29.0975C20.6124 30.7624 20.286 32.749 20.0004 35.1229C19.6982 32.6108 19.3495 30.5338 18.862 28.8124C18.086 26.0728 16.9439 24.1813 15.0367 22.8546C13.5715 21.8283 11.6827 21.1693 9.25004 20.6788C7.97251 20.4212 6.53315 20.2079 4.91126 20.014Z"
      stroke="#E88B00"
      strokeOpacity="0.22"
    />
  </svg>
);

export const Dawn = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50126 6.99361L8.9853 1L6.50362 6.99461M6.5054 6.99639L12.5 4.51471L6.50639 6.99875M6.50639 7.00126L12.5 9.4853L6.5054 7.00362M6.50362 7.0054L8.9853 13L6.50126 7.00639M6.49875 7.00639L4.01471 13L6.49639 7.0054M6.49461 7.00362L0.5 9.4853L6.49361 7.00126M6.49361 6.99875L0.5 4.51471L6.49461 6.99639M6.49639 6.99461L4.01471 1L6.49875 6.99361"
      stroke="#766478"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GoldDawn = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00126 6.99361L9.4853 1L7.00362 6.99461M7.0054 6.99639L13 4.51471L7.00639 6.99875M7.00639 7.00126L13 9.4853L7.0054 7.00362M7.00362 7.0054L9.4853 13L7.00126 7.00639M6.99875 7.00639L4.51471 13L6.99639 7.0054M6.99461 7.00362L1 9.4853L6.99361 7.00126M6.99361 6.99875L1 4.51471L6.99461 6.99639M6.99639 6.99461L4.51471 1L6.99875 6.99361"
      stroke="#FFCB43"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Gem = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00033 4H24.0003L29.3337 12L16.0003 29.3333L2.66699 12L8.00033 4Z"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 4L10.667 12L16.0003 29.3333L21.3337 12L17.3337 4"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66699 12H29.3337"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Flower = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 16C20 18.2091 18.2091 20 16 20M20 16C20 13.7909 18.2091 12 16 12M20 16H22M16 20C13.7909 20 12 18.2091 12 16M16 20V22M12 16C12 13.7909 13.7909 12 16 12M12 16H10M16 12V10M16 22C16 23.1867 15.6481 24.3467 14.9888 25.3334C14.3295 26.3201 13.3925 27.0892 12.2961 27.5433C11.1997 27.9974 9.99335 28.1162 8.82946 27.8847C7.66557 27.6532 6.59648 27.0818 5.75736 26.2426C4.91825 25.4035 4.3468 24.3344 4.11529 23.1705C3.88378 22.0067 4.0026 20.8003 4.45673 19.7039C4.91085 18.6075 5.67989 17.6705 6.66658 17.0112C7.65328 16.3519 8.81331 16 10 16M16 22C16 23.1867 16.3519 24.3467 17.0112 25.3334C17.6705 26.3201 18.6075 27.0892 19.7039 27.5433C20.8003 27.9974 22.0067 28.1162 23.1705 27.8847C24.3344 27.6532 25.4035 27.0818 26.2426 26.2426C27.0818 25.4035 27.6532 24.3344 27.8847 23.1705C28.1162 22.0067 27.9974 20.8003 27.5433 19.7039C27.0892 18.6075 26.3201 17.6705 25.3334 17.0112C24.3467 16.3519 23.1867 16 22 16M10 16C8.81331 16 7.65328 15.6481 6.66658 14.9888C5.67989 14.3295 4.91085 13.3925 4.45673 12.2961C4.0026 11.1997 3.88378 9.99335 4.11529 8.82946C4.3468 7.66557 4.91825 6.59648 5.75736 5.75736C6.59648 4.91825 7.66557 4.3468 8.82946 4.11529C9.99335 3.88378 11.1997 4.0026 12.2961 4.45673C13.3925 4.91085 14.3295 5.67989 14.9888 6.66658C15.6481 7.65328 16 8.81331 16 10M16 10C16 8.81331 16.3519 7.65328 17.0112 6.66658C17.6705 5.67989 18.6075 4.91085 19.7039 4.45673C20.8003 4.0026 22.0067 3.88378 23.1705 4.11529C24.3344 4.3468 25.4035 4.91825 26.2426 5.75736C27.0818 6.59648 27.6532 7.66557 27.8847 8.82946C28.1162 9.99335 27.9974 11.1997 27.5433 12.2961C27.0892 13.3925 26.3201 14.3295 25.3334 14.9888C24.3467 15.6481 23.1867 16 22 16M10.6667 10.6667L13.1733 13.1733M18.8267 13.1733L21.3333 10.6667M10.6667 21.3333L13.1733 18.8267M18.8267 18.8267L21.3333 21.3333"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HandCoins = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 20H17.3333C18.0406 20 18.7188 19.7191 19.2189 19.219C19.719 18.7189 20 18.0406 20 17.3333C20 16.6261 19.719 15.9478 19.2189 15.4477C18.7188 14.9476 18.0406 14.6667 17.3333 14.6667H13.3333C12.5333 14.6667 11.8667 14.9333 11.4667 15.4667L3.99999 22.6667M9.33332 28L11.4667 26.1333C11.8667 25.6 12.5333 25.3333 13.3333 25.3333H18.6667C20.1333 25.3333 21.4667 24.8 22.4 23.7333L28.5333 17.8667C29.0478 17.3804 29.3481 16.7097 29.3681 16.0021C29.3881 15.2945 29.1262 14.6079 28.64 14.0933C28.1538 13.5788 27.4831 13.2785 26.7754 13.2585C26.0678 13.2385 25.3812 13.5004 24.8667 13.9867L19.2667 19.1867M2.66666 21.3333L10.6667 29.3333M25.2 12C25.2 14.1355 23.4688 15.8667 21.3333 15.8667C19.1978 15.8667 17.4667 14.1355 17.4667 12C17.4667 9.8645 19.1978 8.13334 21.3333 8.13334C23.4688 8.13334 25.2 9.8645 25.2 12ZM12 6.66667C12 8.87581 10.2091 10.6667 7.99999 10.6667C5.79085 10.6667 3.99999 8.87581 3.99999 6.66667C3.99999 4.45753 5.79085 2.66667 7.99999 2.66667C10.2091 2.66667 12 4.45753 12 6.66667Z"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Smile = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0003 29.3332C23.3641 29.3332 29.3337 23.3636 29.3337 15.9998C29.3337 8.63604 23.3641 2.6665 16.0003 2.6665C8.63653 2.6665 2.66699 8.63604 2.66699 15.9998C2.66699 23.3636 8.63653 29.3332 16.0003 29.3332Z"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.667 18.6665C10.667 18.6665 12.667 21.3332 16.0003 21.3332C19.3337 21.3332 21.3337 18.6665 21.3337 18.6665"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12H12.0133"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12H20.0133"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HeartHand = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3337 18.6667C27.3203 16.72 29.3337 14.3867 29.3337 11.3333C29.3337 9.38841 28.561 7.52315 27.1858 6.14788C25.8105 4.77262 23.9452 4 22.0003 4C19.6537 4 18.0003 4.66667 16.0003 6.66667C14.0003 4.66667 12.347 4 10.0003 4C8.0554 4 6.19014 4.77262 4.81488 6.14788C3.43961 7.52315 2.66699 9.38841 2.66699 11.3333C2.66699 14.4 4.66699 16.7333 6.66699 18.6667L16.0003 28L25.3337 18.6667Z"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 6.66667L12.0533 10.6133C11.7824 10.8823 11.5674 11.2022 11.4207 11.5546C11.274 11.907 11.1984 12.2849 11.1984 12.6667C11.1984 13.0484 11.274 13.4264 11.4207 13.7788C11.5674 14.1312 11.7824 14.4511 12.0533 14.72C13.1467 15.8133 14.8933 15.8533 16.0533 14.8133L18.8133 12.28C19.5051 11.6523 20.4058 11.3045 21.34 11.3045C22.2742 11.3045 23.1749 11.6523 23.8667 12.28L27.8133 15.8267"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9997 20.0002L21.333 17.3335"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9997 24.0002L17.333 21.3335"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Briefcase = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 16H16.0133"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3337 7.99984V5.33317C21.3337 4.62593 21.0527 3.94765 20.5526 3.44755C20.0525 2.94746 19.3742 2.6665 18.667 2.6665H13.3337C12.6264 2.6665 11.9481 2.94746 11.448 3.44755C10.9479 3.94765 10.667 4.62593 10.667 5.33317V7.99984"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3337 17.3335C25.3774 19.9455 20.7411 21.3379 16.0003 21.3379C11.2596 21.3379 6.62327 19.9455 2.66699 17.3335"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.667 8H5.33366C3.8609 8 2.66699 9.19391 2.66699 10.6667V24C2.66699 25.4728 3.8609 26.6667 5.33366 26.6667H26.667C28.1397 26.6667 29.3337 25.4728 29.3337 24V10.6667C29.3337 9.19391 28.1397 8 26.667 8Z"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 20L16 12L8 20"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12L16 20L24 12"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DotsGrid = () => (
  <svg
    width="104"
    height="96"
    viewBox="0 0 104 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" clipPath="url(#clip0_527_433)">
      <path
        d="M11.9831 14.624C11.4391 14.624 10.9751 14.4373 10.5911 14.064C10.2178 13.68 10.0311 13.216 10.0311 12.672C10.0311 12.1173 10.2178 11.6533 10.5911 11.28C10.9751 10.896 11.4391 10.704 11.9831 10.704C12.5378 10.704 13.0071 10.896 13.3911 11.28C13.7751 11.6533 13.9671 12.1173 13.9671 12.672C13.9671 13.216 13.7751 13.68 13.3911 14.064C13.0071 14.4373 12.5378 14.624 11.9831 14.624Z"
        fill="#D68C00"
      />
      <path
        d="M37.9831 14.624C37.4391 14.624 36.9751 14.4373 36.5911 14.064C36.2178 13.68 36.0311 13.216 36.0311 12.672C36.0311 12.1173 36.2178 11.6533 36.5911 11.28C36.9751 10.896 37.4391 10.704 37.9831 10.704C38.5378 10.704 39.0071 10.896 39.3911 11.28C39.7751 11.6533 39.9671 12.1173 39.9671 12.672C39.9671 13.216 39.7751 13.68 39.3911 14.064C39.0071 14.4373 38.5378 14.624 37.9831 14.624Z"
        fill="#D68C00"
      />
      <path
        d="M63.9831 14.624C63.4391 14.624 62.9751 14.4373 62.5911 14.064C62.2178 13.68 62.0311 13.216 62.0311 12.672C62.0311 12.1173 62.2178 11.6533 62.5911 11.28C62.9751 10.896 63.4391 10.704 63.9831 10.704C64.5378 10.704 65.0071 10.896 65.3911 11.28C65.7751 11.6533 65.9671 12.1173 65.9671 12.672C65.9671 13.216 65.7751 13.68 65.3911 14.064C65.0071 14.4373 64.5378 14.624 63.9831 14.624Z"
        fill="#D68C00"
      />
      <path
        d="M89.9831 14.624C89.4391 14.624 88.9751 14.4373 88.5911 14.064C88.2178 13.68 88.0311 13.216 88.0311 12.672C88.0311 12.1173 88.2178 11.6533 88.5911 11.28C88.9751 10.896 89.4391 10.704 89.9831 10.704C90.5378 10.704 91.0071 10.896 91.3911 11.28C91.7751 11.6533 91.9671 12.1173 91.9671 12.672C91.9671 13.216 91.7751 13.68 91.3911 14.064C91.0071 14.4373 90.5378 14.624 89.9831 14.624Z"
        fill="#D68C00"
      />
      <path
        d="M11.9831 38.624C11.4391 38.624 10.9751 38.4373 10.5911 38.064C10.2178 37.68 10.0311 37.216 10.0311 36.672C10.0311 36.1173 10.2178 35.6533 10.5911 35.28C10.9751 34.896 11.4391 34.704 11.9831 34.704C12.5378 34.704 13.0071 34.896 13.3911 35.28C13.7751 35.6533 13.9671 36.1173 13.9671 36.672C13.9671 37.216 13.7751 37.68 13.3911 38.064C13.0071 38.4373 12.5378 38.624 11.9831 38.624Z"
        fill="#D68C00"
      />
      <path
        d="M37.9831 38.624C37.4391 38.624 36.9751 38.4373 36.5911 38.064C36.2178 37.68 36.0311 37.216 36.0311 36.672C36.0311 36.1173 36.2178 35.6533 36.5911 35.28C36.9751 34.896 37.4391 34.704 37.9831 34.704C38.5378 34.704 39.0071 34.896 39.3911 35.28C39.7751 35.6533 39.9671 36.1173 39.9671 36.672C39.9671 37.216 39.7751 37.68 39.3911 38.064C39.0071 38.4373 38.5378 38.624 37.9831 38.624Z"
        fill="#D68C00"
      />
      <path
        d="M63.9831 38.624C63.4391 38.624 62.9751 38.4373 62.5911 38.064C62.2178 37.68 62.0311 37.216 62.0311 36.672C62.0311 36.1173 62.2178 35.6533 62.5911 35.28C62.9751 34.896 63.4391 34.704 63.9831 34.704C64.5378 34.704 65.0071 34.896 65.3911 35.28C65.7751 35.6533 65.9671 36.1173 65.9671 36.672C65.9671 37.216 65.7751 37.68 65.3911 38.064C65.0071 38.4373 64.5378 38.624 63.9831 38.624Z"
        fill="#D68C00"
      />
      <path
        d="M89.9831 38.624C89.4391 38.624 88.9751 38.4373 88.5911 38.064C88.2178 37.68 88.0311 37.216 88.0311 36.672C88.0311 36.1173 88.2178 35.6533 88.5911 35.28C88.9751 34.896 89.4391 34.704 89.9831 34.704C90.5378 34.704 91.0071 34.896 91.3911 35.28C91.7751 35.6533 91.9671 36.1173 91.9671 36.672C91.9671 37.216 91.7751 37.68 91.3911 38.064C91.0071 38.4373 90.5378 38.624 89.9831 38.624Z"
        fill="#D68C00"
      />
      <path
        d="M11.9831 62.624C11.4391 62.624 10.9751 62.4373 10.5911 62.064C10.2178 61.68 10.0311 61.216 10.0311 60.672C10.0311 60.1173 10.2178 59.6533 10.5911 59.28C10.9751 58.896 11.4391 58.704 11.9831 58.704C12.5378 58.704 13.0071 58.896 13.3911 59.28C13.7751 59.6533 13.9671 60.1173 13.9671 60.672C13.9671 61.216 13.7751 61.68 13.3911 62.064C13.0071 62.4373 12.5378 62.624 11.9831 62.624Z"
        fill="#D68C00"
      />
      <path
        d="M37.9831 62.624C37.4391 62.624 36.9751 62.4373 36.5911 62.064C36.2178 61.68 36.0311 61.216 36.0311 60.672C36.0311 60.1173 36.2178 59.6533 36.5911 59.28C36.9751 58.896 37.4391 58.704 37.9831 58.704C38.5378 58.704 39.0071 58.896 39.3911 59.28C39.7751 59.6533 39.9671 60.1173 39.9671 60.672C39.9671 61.216 39.7751 61.68 39.3911 62.064C39.0071 62.4373 38.5378 62.624 37.9831 62.624Z"
        fill="#D68C00"
      />
      <path
        d="M63.9831 62.624C63.4391 62.624 62.9751 62.4373 62.5911 62.064C62.2178 61.68 62.0311 61.216 62.0311 60.672C62.0311 60.1173 62.2178 59.6533 62.5911 59.28C62.9751 58.896 63.4391 58.704 63.9831 58.704C64.5378 58.704 65.0071 58.896 65.3911 59.28C65.7751 59.6533 65.9671 60.1173 65.9671 60.672C65.9671 61.216 65.7751 61.68 65.3911 62.064C65.0071 62.4373 64.5378 62.624 63.9831 62.624Z"
        fill="#D68C00"
      />
      <path
        d="M89.9831 62.624C89.4391 62.624 88.9751 62.4373 88.5911 62.064C88.2178 61.68 88.0311 61.216 88.0311 60.672C88.0311 60.1173 88.2178 59.6533 88.5911 59.28C88.9751 58.896 89.4391 58.704 89.9831 58.704C90.5378 58.704 91.0071 58.896 91.3911 59.28C91.7751 59.6533 91.9671 60.1173 91.9671 60.672C91.9671 61.216 91.7751 61.68 91.3911 62.064C91.0071 62.4373 90.5378 62.624 89.9831 62.624Z"
        fill="#D68C00"
      />
      <path
        d="M11.9831 86.624C11.4391 86.624 10.9751 86.4373 10.5911 86.064C10.2178 85.68 10.0311 85.216 10.0311 84.672C10.0311 84.1173 10.2178 83.6533 10.5911 83.28C10.9751 82.896 11.4391 82.704 11.9831 82.704C12.5378 82.704 13.0071 82.896 13.3911 83.28C13.7751 83.6533 13.9671 84.1173 13.9671 84.672C13.9671 85.216 13.7751 85.68 13.3911 86.064C13.0071 86.4373 12.5378 86.624 11.9831 86.624Z"
        fill="#D68C00"
      />
      <path
        d="M37.9831 86.624C37.4391 86.624 36.9751 86.4373 36.5911 86.064C36.2178 85.68 36.0311 85.216 36.0311 84.672C36.0311 84.1173 36.2178 83.6533 36.5911 83.28C36.9751 82.896 37.4391 82.704 37.9831 82.704C38.5378 82.704 39.0071 82.896 39.3911 83.28C39.7751 83.6533 39.9671 84.1173 39.9671 84.672C39.9671 85.216 39.7751 85.68 39.3911 86.064C39.0071 86.4373 38.5378 86.624 37.9831 86.624Z"
        fill="#D68C00"
      />
      <path
        d="M63.9831 86.624C63.4391 86.624 62.9751 86.4373 62.5911 86.064C62.2178 85.68 62.0311 85.216 62.0311 84.672C62.0311 84.1173 62.2178 83.6533 62.5911 83.28C62.9751 82.896 63.4391 82.704 63.9831 82.704C64.5378 82.704 65.0071 82.896 65.3911 83.28C65.7751 83.6533 65.9671 84.1173 65.9671 84.672C65.9671 85.216 65.7751 85.68 65.3911 86.064C65.0071 86.4373 64.5378 86.624 63.9831 86.624Z"
        fill="#D68C00"
      />
      <path
        d="M89.9831 86.624C89.4391 86.624 88.9751 86.4373 88.5911 86.064C88.2178 85.68 88.0311 85.216 88.0311 84.672C88.0311 84.1173 88.2178 83.6533 88.5911 83.28C88.9751 82.896 89.4391 82.704 89.9831 82.704C90.5378 82.704 91.0071 82.896 91.3911 83.28C91.7751 83.6533 91.9671 84.1173 91.9671 84.672C91.9671 85.216 91.7751 85.68 91.3911 86.064C91.0071 86.4373 90.5378 86.624 89.9831 86.624Z"
        fill="#D68C00"
      />
    </g>
    <defs>
      <clipPath id="clip0_527_433">
        <rect width="154" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InstagramLogo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
      stroke="#FFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9997 11.3698C16.1231 12.2021 15.981 13.052 15.5935 13.7988C15.206 14.5456 14.5929 15.1512 13.8413 15.5295C13.0898 15.9077 12.2382 16.0394 11.4075 15.9057C10.5768 15.7721 9.80947 15.3799 9.21455 14.785C8.61962 14.1901 8.22744 13.4227 8.09377 12.592C7.96011 11.7614 8.09177 10.9097 8.47003 10.1582C8.84829 9.40667 9.45389 8.79355 10.2007 8.40605C10.9475 8.01856 11.7975 7.8764 12.6297 7.99981C13.4786 8.1257 14.2646 8.52128 14.8714 9.12812C15.4782 9.73496 15.8738 10.5209 15.9997 11.3698Z"
      stroke="#FFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 6.5H17.51"
      stroke="#FFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Phone = ({ fill }: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill ? fill : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 2C16.0882 2.21477 17.9922 3.1188 19.4469 4.56258C20.9015 6.00636 21.8199 7.90341 22.05 9.94"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 6C15.0335 6.19394 15.936 6.67903 16.6404 7.39231C17.3447 8.10559 17.8184 9.01413 18 10"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Mail = ({ fill }: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill ? fill : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 7L13.03 12.7C12.7213 12.8934 12.3643 12.996 12 12.996C11.6357 12.996 11.2787 12.8934 10.97 12.7L2 7"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Location = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#766478"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 16 12 22 12 22C12 22 4 16 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Tick = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 6L9.5 17L4.5 12"
      stroke="#1E1A1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Radio = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
      fill="#5A495B"
    />
  </svg>
);

export const CheckedRadio = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C13.3833 17 14.5625 16.5125 15.5375 15.5375C16.5125 14.5625 17 13.3833 17 12C17 10.6167 16.5125 9.4375 15.5375 8.4625C14.5625 7.4875 13.3833 7 12 7C10.6167 7 9.4375 7.4875 8.4625 8.4625C7.4875 9.4375 7 10.6167 7 12C7 13.3833 7.4875 14.5625 8.4625 15.5375C9.4375 16.5125 10.6167 17 12 17ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
      fill="#5A495B"
    />
  </svg>
);

export const Facebook = () => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      fill="#FFCB43"
    />
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      stroke="url(#paint0_linear_2921_3650)"
    />
    <g clipPath="url(#clip0_2921_3650)">
      <path
        d="M37 25.1641C37 18.5366 31.6274 13.1641 25 13.1641C18.3726 13.1641 13 18.5366 13 25.1641C13 31.1535 17.3882 36.118 23.125 37.0183V28.6328H20.0781V25.1641H23.125V22.5203C23.125 19.5128 24.9166 17.8516 27.6576 17.8516C28.9701 17.8516 30.3438 18.0859 30.3438 18.0859V21.0391H28.8306C27.34 21.0391 26.875 21.9641 26.875 22.9141V25.1641H30.2031L29.6711 28.6328H26.875V37.0183C32.6118 36.118 37 31.1535 37 25.1641Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2921_3650"
        x1="25"
        y1="1.16406"
        x2="25"
        y2="49.1641"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.278527" stopColor="#FFCB43" />
        <stop offset="1" stopColor="#FFCB43" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2921_3650">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 13.1641)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Instagram = () => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      fill="#FFCB43"
    />
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      stroke="url(#paint0_linear_2921_3653)"
    />
    <path
      d="M29.19 15.1641H20.81C17.17 15.1641 15 17.3341 15 20.9741V29.3441C15 32.9941 17.17 35.1641 20.81 35.1641H29.18C32.82 35.1641 34.99 32.9941 34.99 29.3541V20.9741C35 17.3341 32.83 15.1641 29.19 15.1641ZM25 29.0441C22.86 29.0441 21.12 27.3041 21.12 25.1641C21.12 23.0241 22.86 21.2841 25 21.2841C27.14 21.2841 28.88 23.0241 28.88 25.1641C28.88 27.3041 27.14 29.0441 25 29.0441ZM30.92 20.0441C30.87 20.1641 30.8 20.2741 30.71 20.3741C30.61 20.4641 30.5 20.5341 30.38 20.5841C30.26 20.6341 30.13 20.6641 30 20.6641C29.73 20.6641 29.48 20.5641 29.29 20.3741C29.2 20.2741 29.13 20.1641 29.08 20.0441C29.03 19.9241 29 19.7941 29 19.6641C29 19.5341 29.03 19.4041 29.08 19.2841C29.13 19.1541 29.2 19.0541 29.29 18.9541C29.52 18.7241 29.87 18.6141 30.19 18.6841C30.26 18.6941 30.32 18.7141 30.38 18.7441C30.44 18.7641 30.5 18.7941 30.56 18.8341C30.61 18.8641 30.66 18.9141 30.71 18.9541C30.8 19.0541 30.87 19.1541 30.92 19.2841C30.97 19.4041 31 19.5341 31 19.6641C31 19.7941 30.97 19.9241 30.92 20.0441Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2921_3653"
        x1="25"
        y1="1.16406"
        x2="25"
        y2="49.1641"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.278527" stopColor="#FFCB43" />
        <stop offset="1" stopColor="#FFCB43" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const LinkedIn = () => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      fill="#FFCB43"
    />
    <rect
      x="0.5"
      y="0.664062"
      width="49"
      height="49"
      rx="24.5"
      stroke="url(#paint0_linear_2921_3655)"
    />
    <g clipPath="url(#clip0_2921_3655)">
      <path
        d="M35.2234 13.1641H14.7719C13.7922 13.1641 13 13.9375 13 14.8938V35.4297C13 36.3859 13.7922 37.1641 14.7719 37.1641H35.2234C36.2031 37.1641 37 36.3859 37 35.4344V14.8938C37 13.9375 36.2031 13.1641 35.2234 13.1641ZM20.1203 33.6156H16.5578V22.1594H20.1203V33.6156ZM18.3391 20.5984C17.1953 20.5984 16.2719 19.675 16.2719 18.5359C16.2719 17.3969 17.1953 16.4734 18.3391 16.4734C19.4781 16.4734 20.4016 17.3969 20.4016 18.5359C20.4016 19.6703 19.4781 20.5984 18.3391 20.5984ZM33.4516 33.6156H29.8937V28.0469C29.8937 26.7203 29.8703 25.0094 28.0422 25.0094C26.1906 25.0094 25.9094 26.4578 25.9094 27.9531V33.6156H22.3563V22.1594H25.7687V23.725H25.8156C26.2891 22.825 27.4516 21.8734 29.1813 21.8734C32.7859 21.8734 33.4516 24.2453 33.4516 27.3297V33.6156Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2921_3655"
        x1="25"
        y1="1.16406"
        x2="25"
        y2="49.1641"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.278527" stopColor="#FFCB43" />
        <stop offset="1" stopColor="#FFCB43" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2921_3655">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 13.1641)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Pinterest = () => (
  <svg
    fill="#FFCB43"
    height="50"
    width="50"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-143 145 512 512"
  >
    <path
      d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M113,528.3
    c-12.6,0-24.8-1.9-36.3-5.3c4.9-7.7,10.2-17.6,12.9-27.4c1.6-5.7,9-35.2,9-35.2c4.4,8.5,17.4,15.9,31.3,15.9
    c41.2,0,69.1-37.5,69.1-87.7c0-38-32.2-73.3-81-73.3c-60.8,0-91.5,43.6-91.5,80c0,22,8.3,41.6,26.2,48.9c2.9,1.2,5.5,0,6.4-3.2
    c0.6-2.2,2-7.9,2.6-10.3c0.9-3.2,0.5-4.3-1.8-7.1c-5.1-6.1-8.4-13.9-8.4-25.1c0-32.3,24.2-61.3,63-61.3c34.4,0,53.3,21,53.3,49
    c0,36.9-16.3,68-40.6,68c-13.4,0-23.4-11.1-20.2-24.6c3.8-16.2,11.3-33.7,11.3-45.4c0-10.5-5.6-19.2-17.3-19.2
    c-13.7,0-24.7,14.2-24.7,33.1c0,12.1,4.1,20.2,4.1,20.2s-14,59.4-16.5,69.7c-2.3,9.7-2.6,20.5-2.2,29.4
    C16.5,497.8-15,452.7-15,400.3c0-70.7,57.3-128,128-128s128,57.3,128,128S183.7,528.3,113,528.3z"
    />
  </svg>
);

export const Shield = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12L11 14L15 10M20 13C20 18 16.5 20.5 12.34 21.95C12.1222 22.0238 11.8855 22.0203 11.67 21.94C7.5 20.5 4 18 4 13V6.00002C4 5.73481 4.10536 5.48045 4.29289 5.29292C4.48043 5.10538 4.73478 5.00002 5 5.00002C7 5.00002 9.5 3.80002 11.24 2.28002C11.4519 2.09902 11.7214 1.99957 12 1.99957C12.2786 1.99957 12.5481 2.09902 12.76 2.28002C14.51 3.81002 17 5.00002 19 5.00002C19.2652 5.00002 19.5196 5.10538 19.7071 5.29292C19.8946 5.48045 20 5.73481 20 6.00002V13Z"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Refund = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 14L4 9M4 9L9 4M4 9H14.5C15.2223 9 15.9375 9.14226 16.6048 9.41866C17.272 9.69506 17.8784 10.1002 18.3891 10.6109C18.8998 11.1216 19.3049 11.728 19.5813 12.3952C19.8577 13.0625 20 13.7777 20 14.5C20 15.2223 19.8577 15.9375 19.5813 16.6048C19.3049 17.272 18.8998 17.8784 18.3891 18.3891C17.8784 18.8998 17.272 19.3049 16.6048 19.5813C15.9375 19.8577 15.2223 20 14.5 20H11"
      stroke="#E88B00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckCircle = () => (
  <svg
    width="200"
    height="201"
    viewBox="0 0 200 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M183.333 92.8334V100.5C183.323 118.47 177.504 135.956 166.744 150.349C155.985 164.741 140.861 175.271 123.628 180.366C106.395 185.461 87.977 184.849 71.1204 178.622C54.2638 172.394 39.872 160.884 30.0912 145.809C20.3104 130.734 15.6648 112.901 16.8471 94.9693C18.0295 77.0381 24.9766 59.9694 36.6522 46.309C48.3278 32.6485 64.1064 23.1282 81.6347 19.1679C99.1631 15.2076 117.502 17.0195 133.917 24.3334M74.9999 92.1667L99.9999 117.167L183.333 33.8334"
      stroke="#E88B00"
      strokeWidth="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Pendant = () => (
  <svg
    width="71"
    height="4"
    viewBox="0 0 71 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.6515 2L2.15115 0.532135C1.32816 0.5145 0.651489 1.17682 0.651489 2C0.651489 2.82318 1.32816 3.4855 2.15115 3.46786L70.6515 2Z"
      fill="url(#paint0_linear_1122_2009)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1122_2009"
        x1="46.1515"
        y1="2"
        x2="0.651489"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B27500" stopOpacity="0.2" />
        <stop offset="1" stopColor="#B27500" stopOpacity="0.7" />
      </linearGradient>
    </defs>
  </svg>
);

export const List = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#E88B00"
  >
    <path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
  </svg>
);

export const BlueLinkedIn = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="14" fill="#007E85" fill-opacity="0.1" />
    <path
      d="M7 8.88232C7 8.43337 7.15766 8.063 7.47297 7.77121C7.78828 7.4794 8.1982 7.3335 8.7027 7.3335C9.1982 7.3335 9.59909 7.47715 9.90541 7.76447C10.2207 8.06077 10.3784 8.44684 10.3784 8.92272C10.3784 9.3537 10.2252 9.71284 9.91892 10.0002C9.60361 10.2965 9.18919 10.4446 8.67568 10.4446H8.66216C8.16666 10.4446 7.76577 10.2965 7.45946 10.0002C7.15315 9.70387 7 9.33125 7 8.88232ZM7.17568 20.6668V11.6702H10.1757V20.6668H7.17568ZM11.8378 20.6668H14.8378V15.6433C14.8378 15.329 14.8739 15.0866 14.9459 14.916C15.0721 14.6107 15.2635 14.3526 15.5203 14.1416C15.777 13.9306 16.0991 13.8251 16.4865 13.8251C17.4955 13.8251 18 14.503 18 15.8587V20.6668H21V15.5086C21 14.1797 20.6847 13.1719 20.0541 12.485C19.4234 11.7981 18.5901 11.4547 17.5541 11.4547C16.3919 11.4547 15.4865 11.953 14.8378 12.9497V12.9766H14.8243L14.8378 12.9497V11.6702H11.8378C11.8559 11.9575 11.8649 12.8509 11.8649 14.3503C11.8649 15.8498 11.8559 17.9553 11.8378 20.6668Z"
      fill="#0085FF"
    />
  </svg>
);
