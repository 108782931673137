import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "why-choose-zzeeh",
      label: "Why Choose Zzeeh Weddings?",
    },
    {
      id: "services-offered",
      label: "Services Offered by Zzeeh Weddings",
    },
    {
      id: "top-wedding-venues",
      label: "Top Wedding Venues Curated by Zzeeh Weddings",
    },
    {
      id: "client-testimonials",
      label: "Client Testimonials: Real Experiences",
    },
    {
      id: "budget-friendly-packages",
      label: "Budget-Friendly Packages",
    },
    {
      id: "cultural-elements",
      label: "How Zzeeh Weddings Incorporates Cultural Elements",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Expertise in Hindu Weddings",
      body: "Zzeeh Weddings specializes in planning traditional Hindu weddings, from the intricate Mehendi and Sangeet ceremonies to the grand final day. They ensure that every ritual is performed with authenticity, combining modern-day convenience with timeless customs.",
    },
    {
      header: "Customized Wedding Themes",
      body: "Each couple is unique, and so should be their wedding. Zzeeh offers personalized themes, blending vibrant Indian culture with the latest design trends. Whether you want a royal palace theme or a simple yet elegant temple wedding, they have it all.",
    },
    {
      header: "Unique Approach to Wedding Traditions",
      body: "Zzeeh is known for its ability to creatively integrate Hindu rituals with contemporary elements, ensuring that traditions are not just followed but celebrated in a grand and meaningful way.",
    },
  ];

  const list2 = [
    {
      header: "Pre-Wedding Services",
      body: "Planning begins months before the big day. Zzeeh Weddings offers services like venue selection, guest list management, pre-wedding photoshoots, Mehendi, and Sangeet planning. They take care of all the minor details, so you don’t have to.",
    },
    {
      header: "Wedding Day Services",
      body: "On the wedding day, Zzeeh ensures everything runs smoothly. They handle venue décor, guest management, priest arrangements, Baraat, and catering. Every aspect is taken care of with precision to allow the couple to focus on the joy of their day.",
    },
    {
      header: "Post-Wedding Services",
      body: "From post-wedding receptions to special send-offs, Zzeeh manages all after-wedding services. They also offer thank-you notes for guests and arrange honeymoon plans if needed.",
    },
  ];

  const list3 = [
    {
      header: "Temple Weddings",
      body: "For those who want an intimate, sacred ceremony, Zzeeh organizes beautiful temple weddings, complete with all the necessary arrangements for family and guests.",
    },
    {
      header: "Resort Weddings",
      body: "For a luxurious and nature-bound ceremony, resorts around Bangalore offer stunning backdrops. Zzeeh ensures the best resort venues with customized decorations, matching your wedding vision.",
    },
    {
      header: "Palace Weddings",
      body: "For a royal wedding experience, Zzeeh curates grand palace venues with regal décor and arrangements that make you feel like true royalty.",
    },
  ];

  const list4 = [
    {
      header: "Comprehensive Packages",
      body: "Zzeeh Weddings offers all-inclusive packages for clients who want everything from pre-wedding to post-wedding services. These packages are customizable to match your preferences and budget.",
    },
    {
      header: "Flexible Pricing Options",
      body: "For couples on a budget, Zzeeh provides flexible pricing options without compromising on the quality of service. They offer various tiers of packages, ensuring every couple can plan their dream wedding.",
    },
  ];

  const list5 = [
    {
      header: "Traditional Rituals and Customs",
      body: "Zzeeh Weddings ensures that all important Hindu rituals, from the Varmala to the Kanyadaan, are celebrated with the respect and detail they deserve. They work closely with priests and elders to ensure authenticity.",
    },
    {
      header: "Décor that Reflects Hindu Heritage",
      body: "The décor is often a significant part of any wedding, and Zzeeh specializes in creating visual landscapes that reflect Hindu heritage—whether it's intricate rangoli designs or floral mandaps, they cover it all.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Best Hindu Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/8FXTSwpb_Go?si=EUCy6YmbjdT_ljEB"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Planning a wedding is one of the most joyous yet challenging tasks. In
          India, weddings hold a special cultural significance, especially in
          Hindu traditions, where intricate rituals and vibrant customs are
          celebrated over several days. Among the many wedding planners
          available in Bangalore,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          stands out as one of the best for Hindu weddings.
        </p>
        <p>
          In this blog, we explore the various offerings and expertise of Zzeeh
          Weddings, showcasing why they are a perfect fit for your special day.
        </p>
      </section>

      <section id="why-choose-zzeeh" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings?
        </h1>
        <DynamicList listType="list-alpha" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/neha-and-santhosh-wedding-moments.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-alpha" data={list2} />
      </section>

      <section id="top-wedding-venues" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues Curated by Zzeeh Weddings
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has connections with top wedding venues across Bangalore and can help
          you choose the best location to suit your dream wedding.
        </p>
        <DynamicList newLine listType="list-alpha" data={list3} />
      </section>

      <section id="client-testimonials" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials: Real Experiences
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has a long list of satisfied clients. Here's what a few had to say:
        </p>
        <p>
          <i className="font-medium">
            "Zzeeh Weddings made our traditional Hindu wedding flawless. From
            the Sangeet to the reception, everything was beyond perfect."
          </i>{" "}
          – Rohan and Priya
        </p>
        <p>
          <i className="font-medium">
            "Their attention to detail and seamless execution made our wedding
            stress-free. Zzeeh Weddings is simply the best!"
          </i>{" "}
          – Anjali and Karthik
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/intimate-indian-wedding.webp"
        alt="budget-decor-ideas"
      />

      <section id="budget-friendly-packages" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Packages
        </h3>
        <DynamicList newLine listType="list-alpha" data={list4} />
      </section>

      <section id="cultural-elements" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Zzeeh Weddings Incorporates Cultural Elements
        </h3>
        <DynamicList listType="list-decimal" data={list5} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/intimate-wedding-outdoor.webp"
        alt="success-stories"
      />

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has earned its reputation as one of the best Hindu wedding planners in
          Bangalore by combining tradition with modern elegance. Whether you’re
          looking for a royal palace wedding or an intimate temple ceremony,
          Zzeeh ensures your wedding day is memorable and stress-free.
        </p>
        <p>
          For more information or to get started on planning your dream wedding,
          contact Zzeeh Weddings today!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
