import React, { useEffect, useState } from "react";
import BlogSubHeader from "../../ui/BlogSubHeader";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "magic-of-wedding-planners",
      label: "The Magic of Wedding Planners: Why You Need One",
    },
    {
      id: "bangalore-wedding-destination",
      label: "Why Bangalore is the Ultimate Wedding Destination",
    },
    {
      id: "best-wedding-planners",
      label: "Best Wedding Planners in Bangalore",
    },
    { id: "zzeeh-weddings", label: "Zzeeh Weddings: A Symphony of Dreams" },
    { id: "experience-zzeeh", label: "Experience the Charm of Zzeeh" },
    {
      id: "vision-to-life",
      label: "How Zzeeh Weddings Brings Your Vision to Life",
    },
    { id: "what-sets-zzeeh-apart", label: "What Sets Zzeeh Apart" },
    {
      id: "customization-detail",
      label: "Customization at its Best: Tailoring Every Detail",
    },
    {
      id: "creative-excellence",
      label: "Creative Excellence: Turning Ideas into Reality",
    },
    {
      id: "zzeeh-services",
      label: "Zzeeh’s Services That Make a Difference",
    },
    {
      id: "full-service-wedding-planning",
      label: "Full-Service Wedding Planning",
    },
    { id: "venue-selection", label: "Exclusive Venue Selection" },
    { id: "personalized-decor", label: "Personalized Décor and Design" },
    {
      id: "guest-management",
      label: "Guest Management: Ensuring Perfection for Every Guest",
    },
    {
      id: "client-testimonials",
      label: "Client Testimonials: A Love Story Written by Zzeeh",
    },
    {
      id: "client-reviews",
      label: "What Clients Say: Reviews that Shine Bright",
    },
    {
      id: "conclusion",
      label: "Conclusion: A Dream Wedding with Zzeeh is a Wish Come True",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Zzeeh Weddings: Best Wedding Planners in Bangalore 2024
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/_zWxJi6Ie5Y?si=fDSqS74uYdqGBZWQ"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <BlogSubHeader text="Introduction" />
        <p>
          There’s something magical about weddings. Two souls uniting,
          surrounded by love, laughter, and cherished memories. But behind every
          dream wedding is a team that orchestrates every moment to perfection.
          And when it comes to weddings in Bangalore, no one does it better than{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          .
        </p>
      </section>

      <section id="magic-of-wedding-planners" className="space-y-3">
        <BlogSubHeader text="The Magic of Wedding Planners: Why You Need One" />
        <p>
          A wedding is one of the most important days of your life, but planning
          it can be overwhelming. From choosing the perfect venue to finalizing
          the guest list, every detail matters. That’s where a wedding planner
          steps in—a guiding hand through the chaos, a creative partner that
          turns your dreams into reality. They don’t just plan weddings; they
          craft experiences that linger in hearts forever.
        </p>
      </section>

      <section id="bangalore-wedding-destination" className="space-y-3">
        <BlogSubHeader text="Why Bangalore is the Ultimate Wedding Destination" />
        <p>
          Bangalore, with its sprawling gardens, enchanting weather, and modern
          yet traditional charm, has emerged as a hotspot for weddings. From
          luxurious hotels to serene open-air venues, the city offers an array
          of options for couples. Add to that the expertise of wedding planners
          like Zzeeh, and you have the recipe for an unforgettable event.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/marriage-flower-decoration.webp"
        alt="magic-of-wedding-planners"
      />

      <section id="best-wedding-planners" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Best Wedding Planners in Bangalore
        </h1>
        <p>
          Enter{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          , where creativity meets precision, and every wedding becomes a story
          worth telling. Recognized as one of the finest wedding planners in
          Bangalore, Zzeeh brings together a blend of art, passion, and
          experience to create events that are as unique as the love stories
          they celebrate.
        </p>
      </section>

      <section id="zzeeh-weddings" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: A Symphony of Dreams
        </h2>
        <p>
          Imagine a wedding where every petal in the bouquet sings, every
          candlelight glows a little brighter, and every guest feels like
          royalty. That’s the magic Zzeeh Weddings brings to life. They don’t
          just plan weddings—they compose symphonies of dreams, where every note
          harmonizes with your vision of the perfect day.
        </p>
      </section>

      <section id="experience-zzeeh" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Experience the Charm of Zzeeh
        </h3>
        <p>
          From the moment you meet the Zzeeh team, you’ll feel the charm and
          professionalism that define their work. Their approach is personal yet
          professional, blending creative innovation with careful attention to
          every last detail. With them by your side, you don’t just get a
          wedding planner—you get a trusted confidant who will make your day
          extraordinary.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/wedding-color-decorator.webp"
        alt="bangalore-wedding-destination"
      />

      <section id="vision-to-life" className="space-y-3">
        <BlogSubHeader text="How Zzeeh Weddings Brings Your Vision to Life" />
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          doesn’t believe in cookie-cutter weddings. Each event is a blank
          canvas where your unique love story is the masterpiece. They take the
          time to understand your desires, your dreams, and even the little
          things that matter most to you. And then, they get to work—bringing
          everything together in perfect harmony.
        </p>
      </section>

      <section id="what-sets-zzeeh-apart" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          What Sets Zzeeh Apart"{" "}
        </h2>
        <p>
          Zzeeh isn’t just another wedding planner. What sets them apart is
          their relentless pursuit of excellence and creativity. They treat
          every wedding as if it were their own, pouring their hearts into every
          detail to ensure that the day is nothing short of spectacular.
        </p>
      </section>

      <section id="customization-detail" className="space-y-3">
        <BlogSubHeader text="Customization at its Best: Tailoring Every Detail" />
        <p>
          No two weddings are alike at Zzeeh. Whether you envision a grand
          celebration in a luxurious ballroom or a cozy, intimate gathering in a
          garden, Zzeeh tailors every aspect to match your personal style and
          taste. Their team ensures that no detail, no matter how small, is
          overlooked.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/flower-stage-decoration.webp"
        alt="vision-to-life"
      />

      <section id="creative-excellence" className="space-y-3">
        <BlogSubHeader text="Creative Excellence: Turning Ideas into Reality" />
        <p>
          Have an unconventional idea for your wedding? Zzeeh will make it
          happen. They thrive on creativity, turning even the wildest concepts
          into awe-inspiring realities. From themed décor to surprise elements,
          they go above and beyond to make sure your wedding is one-of-a-kind.
        </p>
      </section>

      <section id="zzeeh-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh's Services That Make a Difference
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers a full range of services designed to take the stress out of
          wedding planning while ensuring that every element of your day is
          flawless.
        </p>
      </section>

      <section id="full-service-wedding-planning" className="space-y-3">
        <BlogSubHeader text="Full-Service Wedding Planning" />
        <p>
          Zzeeh’s full-service planning means that they take care of
          everything—yes, everything! From budgeting to day-of coordination,
          they handle it all with grace and efficiency, allowing you to relax
          and enjoy every moment of your special day.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/flower-wedding-stage-decoration.webp"
        alt="full-service-wedding-planning"
      />

      <section id="venue-selection" className="space-y-3">
        <BlogSubHeader text="Exclusive Venue Selection" />
        <p>
          Choosing the right venue is crucial, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          excels at finding the perfect setting for your wedding. Whether it’s a
          lavish hotel or a serene outdoor garden, they have exclusive
          connections with some of Bangalore’s most sought-after venues.
        </p>
      </section>

      <section id="personalized-decor" className="space-y-3">
        <BlogSubHeader text="Personalized Décor and Design" />
        <p>
          Décor sets the tone for your wedding, and Zzeeh’s team of creative
          designers work tirelessly to bring your vision to life. From floral
          arrangements to lighting, every element is thoughtfully curated to
          reflect your personality and style.
        </p>
      </section>

      <section id="guest-management" className="space-y-3">
        <BlogSubHeader text="Guest Management: Ensuring Perfection for Every Guest" />
        <p>
          Ensuring that your guests have a seamless experience is one of Zzeeh’s
          top priorities. They manage everything from accommodations to
          transportation, making sure your loved ones are well taken care of and
          can fully enjoy your celebration.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/marriage-flower-decoration-price.webp"
        alt="personalized-decor"
      />

      <section id="client-testimonials" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials: A Love Story Written by Zzeeh
        </h2>
        <p>
          The true testament to Zzeeh’s excellence lies in the words of those
          who’ve experienced it. Clients rave about the unforgettable moments,
          the seamless coordination, and the personal touch that Zzeeh brings to
          every event.
        </p>
      </section>

      <section id="client-reviews" className="space-y-3">
        <BlogSubHeader text="What Clients Say: Reviews that Shine Bright" />
        <p>
          “Our wedding was nothing short of a fairytale. Zzeeh took our dreams
          and made them even more beautiful than we imagined.” - A happy couple
        </p>
        <p>
          “Zzeeh is hands down the best wedding planner in Bangalore! Their
          attention to detail is unmatched.” - A satisfied client
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <BlogSubHeader text="Conclusion: A Dream Wedding with Zzeeh is a Wish Come True" />
        <p>
          At the end of the day, your wedding should be a reflection of your
          love, your dreams, and your story. With Zzeeh Weddings, that’s exactly
          what you’ll get. They go beyond planning; they create memories that
          last a lifetime. So, if you’re looking for the best wedding planners
          in Bangalore, Zzeeh is the name to trust—because a dream wedding
          deserves nothing less than perfection.
        </p>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          doesn’t simply create events—they paint dreams upon the canvas of
          time, where every stroke is laced with elegance, and every color
          reflects the shades of love. With them, your wedding is not just a
          celebration; it’s a narrative of passion, devotion, and the delicate
          dance between two souls forever intertwined.
        </p>
        <p>
          Imagine standing beneath the open sky, where the stars seem to shimmer
          a little brighter, as if nature itself has leaned in to witness your
          union. The air carries a soft melody—the music of laughter, whispers
          of joy, and the rhythm of heartbeats in sync. This is the world Zzeeh
          creates, a world where every detail is a love letter, written with
          precision, tenderness, and an artist’s heart.
        </p>
        <p>
          Zzeeh understands that a wedding is not merely a series of moments—it
          is a collection of dreams, hopes, and promises woven into a tapestry.
          Their commitment is to ensure that every thread glows with the light
          of your vision, that every corner whispers the essence of who you are,
          both as individuals and as a couple.
        </p>
        <p>
          With Zzeeh, there is no stress, no chaos. There is only the sweet
          serenity of knowing that everything, down to the tiniest flower petal
          or the softest candle glow, is taken care of. You are free to bask in
          the magic, to dance under the stars, to laugh with abandon, and to
          feel, deeply, the wonder of the day that is uniquely yours.
        </p>
        <p>
          As the night draws to a close, and you stand side by side, looking out
          at the faces of those who have come to witness your love, there is a
          quiet sense of peace. This is your moment, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has made sure it shines with the brilliance it deserves.
        </p>
        <p>
          Because with Zzeeh, a wedding isn’t just an event—it’s a memory, a
          dream, a story told with love, to be cherished for a lifetime.
        </p>
      </section>
      <GetInTouch />
    </div>
  );
};

export default Blog;
