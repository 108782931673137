import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction-destination-weddings",
      label: "Introduction to Destination Weddings",
    },
    {
      id: "why-zzeeh-weddings",
      label: "Why Zzeeh Weddings?",
    },
    {
      id: "popular-destination-locations",
      label: "Popular Destination Wedding Locations in and around Bangalore",
    },
    {
      id: "services-offered",
      label: "Zzeeh Weddings Services",
    },
    {
      id: "customization-options",
      label: "Customization Options with Zzeeh Weddings",
    },
    {
      id: "zzeeh-process",
      label: "Zzeeh Weddings: The Process",
    },
    {
      id: "case-studies",
      label: "Case Studies: Real Destination Weddings by Zzeeh",
    },
    {
      id: "why-bangalore",
      label: "Why Bangalore is a Perfect Destination for Your Wedding",
    },
    {
      id: "faqs",
      label: "Frequently Asked Questions (FAQ)",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Heritage Resorts",
      body: "like the Tamarind Tree and Angsana Oasis",
    },
    {
      header: "Nature-inspired Venues",
      body: "such as farmhouses and outdoor spaces amidst greenery",
    },
    {
      header: "Palaces and Royal Venues",
      body: "for those looking for a regal and majestic celebration.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection",
      body: "Helping couples choose the perfect location.",
    },
    {
      header: "Wedding Décor",
      body: "Designing stunning, unique setups that align with the wedding theme.",
    },
    {
      header: "Photography & Videography",
      body: "Capturing every moment with expert professionals.",
    },
    {
      header: "Guest Management",
      body: "Coordinating accommodations, transportation, and ensuring guest comfort.",
    },
    {
      header: "Catering",
      body: "Collaborating with top caterers to provide a gourmet experience.",
    },
    {
      header: "Entertainment",
      body: "Booking artists and performers to keep guests entertained throughout.",
    },
    {
      header: "Bridal Styling",
      body: "Arranging for top makeup artists, stylists, and grooming experts.",
    },
    {
      header: "Logistics",
      body: "Managing travel arrangements for the couple and guests.",
    },
  ];

  const list3 = [
    {
      header: "Initial Consultation",
      body: "Understanding the couple’s preferences and style.",
    },
    {
      header: "Budget Planning",
      body: "Allocating resources effectively.",
    },
    {
      header: "Event Planning",
      body: "Covering everything from décor to guest management.",
    },
    {
      header: "On-The-Day Coordination",
      body: "to ensure the event flows smoothly and stress-free.",
    },
  ];

  const list4 = [
    {
      header: "",
      body: "A royal-themed wedding in a palace, complete with regal décor and traditional ceremonies.",
    },
    {
      header: "",
      body: "An eco-friendly farmhouse wedding, focusing on sustainability and nature-inspired aesthetics.",
    },
    {
      header: "",
      body: "A luxurious resort wedding that included a grand celebration with multiple events across several days.",
    },
  ];

  const list5 = [
    {
      header: "Pleasant Weather",
      body: "The city enjoys a moderate climate year-round, making it ideal for outdoor weddings.",
    },
    {
      header: "Blend of Tradition and Modernity",
      body: "Whether you want a traditional ceremony or a modern celebration, Bangalore has something to offer.",
    },
    {
      header: "Accessibility",
      body: "Bangalore is well-connected with domestic and international airports, making travel easy for guests.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Destination Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/sb5BNjdbfxk?si=eyaaxQGcyqZhvPIt"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Destination Weddings
        </h1>
        <p>
          Destination weddings are a growing trend, offering couples a chance to
          tie the knot in a picturesque location, away from the hustle of
          everyday life. These weddings combine celebration and vacation, making
          them memorable not only for the couple but for their guests as well.
          Bangalore, with its vibrant culture, diverse locations, and beautiful
          weather, has emerged as a favorite for destination weddings.
        </p>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          , a leading wedding planner in Bangalore, specializes in making these
          dream weddings a reality.
        </p>
      </section>

      <section id="why-zzeeh-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings?
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has established itself as a trusted name in the wedding planning
          industry, especially when it comes to destination weddings. With years
          of experience, the team at Zzeeh understands the importance of
          personalization, attention to detail, and flawless execution.
        </p>
        <p>
          They go beyond traditional planning by curating weddings that reflect
          the couple’s style, preferences, and personality. Their deep knowledge
          of Bangalore's venues and logistics ensures that every event runs
          smoothly, regardless of the scale.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-places-for-destination-weddings.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="popular-destination-locations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Popular Destination Wedding Locations in and around Bangalore
        </h1>

        <p>
          Bangalore offers a wide variety of destination wedding venues, from
          luxurious resorts to serene nature-inspired locations. Some of the top
          picks include:
        </p>
        <DynamicList noColon listType="list-disc" data={list1} />
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has extensive experience in working with these venues and can ensure
          that every aspect is seamlessly managed.
        </p>
      </section>

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings Services
        </h1>
        <p>
          Zzeeh offers a wide range of services, tailored to meet the unique
          needs of each couple:
        </p>
        <DynamicList listType="list-disc" data={list2} />
      </section>

      <section id="customization-options" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Customization Options with Zzeeh Weddings
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          understands that no two weddings are the same. They offer customizable
          options, whether it’s a traditional South Indian wedding or a modern,
          minimalist affair. They provide the flexibility to host intimate
          ceremonies or larger-than-life celebrations, ensuring that every event
          is perfectly aligned with the couple’s vision.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/wedding-ceremony-decorations.webp"
        alt="budget-decor-ideas"
      />

      <section id="zzeeh-process" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: The Process
        </h1>
        <p>
          The Zzeeh team begins the planning process with an in-depth
          consultation to understand the couple’s needs. They work on creating a
          wedding blueprint that details every aspect of the celebration,
          ensuring that every detail is considered. The process involves:
        </p>
        <DynamicList noColon listType="list-disc" data={list3} />
      </section>

      <section id="case-studies" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Case Studies: Real Destination Weddings by Zzeeh
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has successfully executed several memorable weddings:
        </p>
        <DynamicList noColon listType="list-disc" data={list4} />
      </section>

      <section id="why-bangalore" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Bangalore is a Perfect Destination for Your Wedding
        </h1>
        <p>
          Bangalore offers everything a couple might want for their destination
          wedding:
        </p>
        <DynamicList noColon listType="list-disc" data={list5} />
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>
        <p>
          Here are some common questions about planning a destination wedding in
          Bangalore with{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          :
        </p>
        <ul className={`list-disc ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              How far in advance should we plan a destination wedding?
            </p>
            <p>
              It's recommended to start planning at least 6-12 months in advance
              to ensure the best venues and vendors.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What are the legal requirements for getting married in Bangalore?
            </p>
            <p>
              <a
                href="/zzeeh_weddings"
                className="text-blue-500 hover:underline"
              >
                Zzeeh Weddings
              </a>{" "}
              can help navigate the legal formalities, including documentation
              for both local and international couples.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can Zzeeh Weddings help with international guest management?
            </p>
            <p>
              Yes, Zzeeh can assist with travel, accommodation, and special
              requests for international guests.
            </p>
          </li>
        </ul>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/hanging-wedding-decors.webp"
        alt="success-stories"
      />

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Planning a destination wedding can be overwhelming, but with Zzeeh
          Weddings, you can ensure that every detail is taken care of. Their
          expertise in destination weddings in Bangalore, coupled with their
          commitment to personalized service, makes them the ideal partner to
          bring your wedding dreams to life.
        </p>
        <p>
          If you’re looking for a flawless, unforgettable destination wedding in
          Bangalore,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          is the planner to trust.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
