export const faqs = [
  {
    ques: "How much does a wedding planner cost in Bangalore?",
    ans: "The cost of a wedding planners in Bangalore can vary greatly, depending on several factors. Typically, prices range from ₹50,000 to several lakhs. The overall cost depends on the scale of the wedding, the services required, and the level of customization.",
  },
  {
    ques: "At what budget should you hire a wedding planner?",
    ans: "You should consider hiring a wedding planner if your budget is above ₹2,00,000. Wedding planners help in optimizing costs and enhancing the overall quality of the wedding, suitable for both lavish and modest budgets. Planners tailor their services to match your financial plan.",
  },
  {
    ques: "What is the difference between a wedding planner and a wedding coordinator?",
    ans: "A wedding planner oversees the entire wedding planning process, from start to finish. They handle design, vendor selection, and overall event management. Whereas, a wedding coordinator focuses on managing the wedding day itself. They ensure the day’s events run smoothly, following the planner’s established arrangements.",
  },
  {
    ques: "How does one hire a Wedding planner?",
    ans: "To hire a wedding planner, start by researching and shortlisting planners that suit your style and budget. Contact them for a consultation to discuss your wedding vision, preferences, and budget. Review their proposed services and costs, and if aligned with your needs, sign a contract to begin the planning process.",
  },
];

export const general = [
  {
    ques: "Do you offer packages?",
    ans: "Yes, we offer customizable packages to suit different budgets and preferences. We can also create a tailored package based on your specific requirements.",
  },
  {
    ques: "How far in advance should I book your services?",
    ans: "We recommend booking as early as possible, especially for peak wedding seasons. However, we can accommodate last-minute bookings based on availability.",
  },
  {
    ques: "What is your service area?",
    ans: "We primarily serve Bangalore and its surrounding areas. We also offer destination wedding planning services.",
  },
  {
    ques: "Do you handle international weddings?",
    ans: "Yes, we specialize in planning destination weddings and have experience working with clients from different countries.",
  },
];

export const wedding = [
  {
    ques: "Do you offer wedding coordination or full-service planning?",
    ans: "We offer both wedding coordination and full-service planning options.",
  },
  {
    ques: "What is included in your wedding planning packages?",
    ans: "Our packages typically include venue selection, vendor management, decor, catering, entertainment, and overall event coordination.",
  },
  {
    ques: "Do you help with wedding invitations and stationery?",
    ans: "Yes, we can assist with designing and ordering your wedding invitations and stationery.",
  },
  {
    ques: "Can you help with wedding transportation?",
    ans: "Absolutely, we can arrange transportation for you and your guests.",
  },
  {
    ques: "What is your fee structure?",
    ans: "Our fees are based on the scope of services, the number of guests, and the complexity of the event. We offer customized quotes.",
  },
];

export const event = [
  {
    ques: "What types of corporate events do you handle?",
    ans: "We handle a wide range of corporate events, including conferences, product launches, team-building activities, award ceremonies, and gala dinners.",
  },
  {
    ques: "Can you help with event theme development?",
    ans: "Yes, we specialize in creating unique and engaging event themes.",
  },
  {
    ques: "Do you offer audiovisual equipment rental?",
    ans: "Yes, we provide for audiovisual equipment rental.",
  },
  {
    ques: "How do you handle event logistics and transportation?",
    ans: "We have a dedicated team that handles all event logistics, including transportation, accommodation, and venue arrangements.",
  },
];
