import React from "react";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";
import { useParams } from "react-router-dom";
import { blogs } from "../assets/data/blogs";
import FollowUs from "../components/Blogs/FollowUs";
import ScrollToTop from "../components/ui/ScrollToTop";

const Blog = () => {
  const { blog_id } = useParams();
  const blog = blogs.find((b) => b.id === blog_id);
  const BlogContent = blog?.blog;

  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <ScrollToTop />
      <div className="container flex flex-col lg:flex-row min-h-screen px-3 xl:px-0 pb-10 mt-24 lg:mt-40">
        <div className="lg:w-2/3 xl:w-3/4 pb-10 lg:pe-10">
          {BlogContent && <BlogContent />}
        </div>
        <FollowUs />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
