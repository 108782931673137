import React from "react";
import logo from "../../assets/images/logos/zzeeh_logo2.webp";
import { InstagramLogo, Mail, Phone } from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-t from-[#433743] to-[#181418] p-10 min-h-80 flex justify-center w-full z-40">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col justify-between">
            <img
              src={logo}
              className="w-12 cursor-pointer"
              alt="Zzeeh"
              onClick={() => {
                navigate(`/#home`);
                var elementToView = document.getElementById("home");
                elementToView?.scrollIntoView({ behavior: "smooth" });
              }}
            />
            <p className="mt-4 text-pink lg:max-w-[250px]">
              Your end-to-end events planner for premium experiences!
            </p>
            <a
              className="flex flex-row items-center mt-10 lg:mt-auto"
              href="https://www.instagram.com/zzeehweddings/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramLogo />
              <p className="ml-3 text-pink font-medium">
                Follow us{" "}
                <span className="text-white hover:opacity-70 transition-opacity duration-700">
                  @zzeehweddings
                </span>
              </p>
            </a>
            <a
              href="/"
              className="mt-4 text-pink font-gt-super hover:opacity-70 transition-opacity duration-700"
            >
              © 2006 - {year} Zzeeh Productions Pvt Ltd
            </a>
          </div>
          <div className="flex flex-col md:flex-row mt-10 lg:mt-0">
            <div className="md:w-1/2">
              <p className="text-yellow text-sm font-medium mb-5 lg:mb-8 uppercase">
                Quick Links
              </p>
              <div className="flex flex-col space-y-3 xl:space-y-6">
                <a href="/zzeeh_weddings" className="text-grey hover:underline">
                  Weddings
                </a>
                <a href="/terms_of_use" className="text-grey hover:underline">
                  Terms of Use
                </a>
                <a href="/privacy_policy" className="text-grey hover:underline">
                  Privacy Policy
                </a>
                <a href="/refund_policy" className="text-grey hover:underline">
                  Refund Policy
                </a>
                <a href="/contact_us" className="text-grey hover:underline">
                  Contact Us
                </a>
                {/* <a href="/events" className="text-grey hover:underline">
                  Corporate Events
                </a>
                <a href="/" className="text-grey hover:underline">
                  Equipment Rental
                </a> 
                <a href="/gallery" className="text-grey hover:underline">
                  Gallery
                </a>
                <a href="/blogs" className="text-grey hover:underline">
                  Blogs
                </a> */}
              </div>
            </div>
            {/* <div className="md:w-1/3 mt-10 md:mt-0">
              <p className="text-yellow text-sm font-medium mb-5 lg:mb-8 uppercase">
                Support
              </p>
              <div className="flex flex-col space-y-6">
                <a
                  href="/terms_and_conditions"
                  className="text-grey hover:underline"
                >
                  Terms of Use
                </a>
                <a href="/privacy_policy" className="text-grey hover:underline">
                  Privacy Policy
                </a>
                <a href="/refund_policy" className="text-grey hover:underline">
                  Refund Policy
                </a>
                <a href="/contact_us" className="text-grey hover:underline">
                  Contact Us
                </a>
              </div>
            </div> */}
            <div className="mt-10 md:mt-0 md:w-1/2">
              <p className="text-yellow text-sm font-medium mb-8">
                OUR DETAILS
              </p>
              <div className="flex flex-col space-y-6">
                <div className="flex flex-row space-x-3">
                  <div className="w-6">
                    <Phone />
                  </div>
                  <a
                    href="tel:919886400292"
                    className="text-grey hover:underline truncate"
                  >
                    +91 9886400292
                  </a>
                </div>
                <div className="flex flex-row space-x-3">
                  <div className="w-6">
                    <Mail />
                  </div>
                  <a
                    className="text-grey hover:underline"
                    href="mailto:info@zzeeh.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@zzeeh.com
                  </a>
                </div>
                <div className="flex flex-row space-x-3">
                  <div className="w-6">
                    <InstagramLogo />
                  </div>
                  <a
                    className="text-grey hover:underline"
                    href="https://www.instagram.com/zzeehweddings/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @zzeehweddings
                  </a>
                </div>
                {/* <div className="flex flex-row space-x-3">
                  <Map />
                  <a
                    className="text-grey hover:underline flex-[0.9]"
                    href="https://maps.app.goo.gl/kCP7D2Uz8rHMXTnZA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #19 Rahamans Grace, Mosque Rd, above Sangeeta showroom,
                    Pulikeshi Nagar, Bengaluru, Karnataka 560005
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
