import React from "react";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";
import Hero from "../components/AboutUs/Hero";
import Team from "../components/AboutUs/Team";
import VisionMission from "../components/AboutUs/VisionMission";

const AboutUs = () => {
  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <Hero />
      <Team />
      <VisionMission />
      <Footer />
    </div>
  );
};

export default AboutUs;
