import React, { useState, useEffect } from "react";
import NavigationBar from "../components/ui/Navbar";
import Hero from "../components/MainPage/Hero";
import WhyChooseUs from "../components/MainPage/WhyChooseUs";
import ServingQuality from "../components/MainPage/ServingQuality";
import ZzeehGallery from "../components/MainPage/ZzeehGallery";
import FAQs from "../components/MainPage/FAQs";
import Vision from "../components/MainPage/Vision";
import Footer from "../components/ui/Footer";
import FormModalOne from "../components/MainPage/FormModalOne";
import { general } from "../assets/data/faqs";

const MainPage = () => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    var elementToView = document.getElementById("home");
    elementToView?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <Hero />
      <WhyChooseUs setVisible={setModalVisible} />
      <ServingQuality setVisible={setModalVisible} />
      <ZzeehGallery name="Zzeeh's" />
      <Vision setVisible={setModalVisible} />
      <FAQs faqs={general} />
      <Footer />

      <FormModalOne
        modalIsOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </div>
  );
};

export default MainPage;
