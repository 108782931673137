import { BlueLinkedIn } from "../../assets/icons/Icons";

const TeamCard = ({
  member,
  index,
}: {
  member: {
    imageURL: string;
    name: string;
    role: string;
    linkedIn?: string;
  };
  index: number;
}) => {
  const baseDelay = 150;

  return (
    <div
      data-aos="fade-right"
      data-aos-delay={`${baseDelay * index}`}
      className="flex flex-col justify-center items-center text-center p-6 gap-3 rounded-xl bg-white relative"
    >
      {/* LinkedIn Icon */}
      {member.linkedIn && (
        <a
          href={member.linkedIn}
          className="absolute top-2 right-2 w-6 h-6"
          target="_blank"
          rel="noreferrer"
        >
          <BlueLinkedIn />
        </a>
      )}

      {/* Member Image */}
      <img
        src={member.imageURL}
        alt={member.name}
        className="h-44 object-cover"
      />

      {/* Member Details */}
      <div>
        <p className="font-bold font text-xl text-gold mb-1">{member.name}</p>
        <p className="uppercase font-medium text-sm text-gray-600">
          {member.role}
        </p>
      </div>

      {/* About Section */}
      {/* <p className="text-gray-500 text-sm">{member.about}</p> */}
    </div>
  );
};

export default TeamCard;
