import axios from "axios";
import { baseURL } from "./constants";

// Create an instance of axios with default headers and base URL
export const apiClient = axios.create({
  baseURL: baseURL, // Set the base URL from environment variable
  headers: {
    "x-api-key": process.env.REACT_APP_API_SECRET_KEY, // Set the API key from environment variable
  },
});
