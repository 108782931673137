import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "overview",
      label: "Zzeeh Weddings: An Overview",
    },
    {
      id: "why-choose-zzeeh",
      label: "Why Choose Zzeeh Weddings?",
    },
    {
      id: "services-offered",
      label: "Services Offered by Zzeeh Weddings",
    },
    {
      id: "affordable-packages",
      label: "Packages at Affordable Prices",
    },
    {
      id: "success-stories",
      label: "Success Stories – Decor Video Gallery",
    },
    {
      id: "budget-tips",
      label: "Tips for Hiring a Budget-Friendly Wedding Planner in Bangalore",
    },
    // {
    //   id: "client-testimonials",
    //   label: "Client Testimonials",
    // },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Who is Zzeeh Weddings?",
      body: "Zzeeh Weddings is a well-established wedding planning company based in Bangalore, renowned for their high-quality services tailored to different budgets. With years of experience, they have successfully organized numerous weddings, making them a trusted name in the industry.",
    },
    {
      header: "What Makes Them Stand Out?",
      body: "Zzeeh stands out for their flexibility, attention to detail, and ability to bring creative ideas to life without breaking the bank. Whether it's a lavish wedding or an intimate celebration, their planning team ensures that every event is meticulously executed.",
    },
  ];

  const list2 = [
    {
      header: "Customization Options",
      body: "One of the reasons Zzeeh Weddings is considered top-notch is their ability to provide fully customized wedding plans. They understand that every couple has unique preferences, and they tailor their services to fit those needs.",
    },
    {
      header: "Attention to Detail",
      body: "From the smallest décor details to managing the flow of events, Zzeeh leaves no stone unturned. Their comprehensive planning ensures that everything from pre-wedding rituals to the grand reception is perfectly orchestrated.",
    },
    {
      header: "Experience in Managing Budget-Friendly Weddings",
      body: "Zzeeh has a proven track record of organizing elegant weddings within a wide range of budgets. They provide cost-effective solutions without compromising on quality.",
    },
  ];

  const list3 = [
    {
      header: "Venue Selection",
      body: "Finding the perfect venue within budget is a challenge, but Zzeeh Weddings has access to exclusive venues in Bangalore and the surrounding areas, offering you options that suit both your vision and financial constraints.",
    },
    {
      header: "Catering Services",
      body: "Food is a major aspect of any Indian wedding, and Zzeeh offers catering services that are tailored to meet your preferences. They work with some of the best caterers in the industry to ensure high-quality, delicious food.",
    },
    {
      header: "Photography & Videography",
      body: "Capture every moment of your special day with Zzeeh' professional photography and videography services. They work with top photographers to ensure your wedding memories are preserved beautifully.",
    },
    {
      header: "Wedding Décor",
      body: "From classic floral setups to modern themed décor, Zzeeh provides décor services that can transform any space into a stunning wedding venue. Their creative team ensures that every element matches your taste and budget.",
    },
    {
      header: "Entertainment & Music",
      body: "Whether it’s a DJ, live band, or traditional wedding music, Zzeeh Weddings ensures that the entertainment at your wedding is lively and engaging.",
    },
    {
      header: "Guest Management & Hospitality",
      body: "Ensuring that your guests are comfortable is a top priority. Zzeeh provides full guest management services, including transportation, accommodation, and hospitality, making sure that every guest feels special.",
    },
  ];

  const list4 = [
    {
      header: "Budget Wedding Packages",
      body: "These packages are perfect for couples who want a beautiful wedding without overspending. The budget packages include basic services such as venue selection, décor, catering, and guest management.",
    },
    {
      header: "Mid-Range Wedding Packages",
      body: "For couples looking for a balance between luxury and affordability, Zzeeh Weddings provides mid-range packages. These include additional services such as premium décor, entertainment, and high-quality photography.",
    },
    {
      header: "Luxury Wedding Packages (Still within Reach)",
      body: "Zzeeh's luxury packages offer high-end services and experiences, including exclusive venues, designer décor, and premium hospitality, while still being priced competitively for the Bangalore market.",
    },
  ];

  const list5 = [
    {
      header: "Budgeting for a Wedding",
      body: "Understanding your financial constraints is essential before meeting with any wedding planner. Zzeeh Weddings assists couples in setting realistic budgets.",
    },
    {
      header: "Communication with Your Planner",
      body: "Open and frequent communication is key. Zzeeh encourages clients to discuss their vision clearly, ensuring every detail is aligned with their expectations.",
    },
    {
      header: "Prioritizing Key Elements",
      body: "Identifying your top priorities will help focus on what truly matters. Whether it’s venue, food, or décor, Zzeeh guides clients to make wise choices.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Top-Notch Wedding Planners in Bangalore at Budget Price
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/cbpIH_CkykM?si=7iSp2jav42Nou8zj"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Bangalore, known as the "Silicon Valley of India," has become a
          hotspot for destination and local weddings. However, the rising costs
          associated with wedding planning can be overwhelming for many couples.
          This is where professional wedding planners come in handy. Hiring the
          right planner can help reduce the stress of wedding arrangements while
          keeping everything within budget.
        </p>
        <p>
          In this blog, we introduce{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          , one of the top wedding planners in Bangalore, known for delivering
          dream weddings at affordable prices.
        </p>
      </section>

      <section id="overview" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: An Overview
        </h1>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/top-banquet-halls-bangalore.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="why-choose-zzeeh" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings?
        </h1>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Zzeeh Weddings{" "}
        </h1>
        <p>
          Zzeeh Weddings offers a full suite of services designed to make your
          big day stress-free and memorable:
        </p>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/small-wedding-reception.webp"
        alt="budget-decor-ideas"
      />

      <section id="affordable-packages" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Packages at Affordable Prices
        </h1>
        <p>
          Zzeeh Weddings offers various packages designed to suit different
          budget ranges:
        </p>
        <DynamicList listType="list-disc" data={list4} />
      </section>

      <section id="success-stories" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Success Stories
        </h3>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has successfully managed many weddings with unique themes and cultural
          aspects. From traditional South Indian weddings to destination
          weddings with modern flair, their portfolio is diverse. In this
          section, we’ll highlight a few case studies of beautiful weddings that
          they have coordinated, demonstrating their ability to work within
          various budgets.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-decorations-indian.webp"
        alt="success-stories"
      />

      <section id="budget-tips" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tips for Hiring a Budget-Friendly Wedding Planner in Bangalore
        </h3>
        <DynamicList listType="list-decimal" data={list5} />
      </section>

      {/* <section id="client-testimonials" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials
        </h1>
        <p>
          Don’t just take our word for it—hear from couples who have worked with
          Zzeeh Weddings. Clients frequently mention the team’s professionalism,
          creativity, and ability to stay within budget without compromising
          quality.
        </p>
      </section> */}

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Planning a wedding can be daunting, especially when working within a
          budget. However, with the right wedding planner, it is possible to
          have a beautiful and memorable day without breaking the bank.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          provides high-quality services, tailored packages, and expert guidance
          to make your wedding day everything you’ve dreamed of. If you're
          looking for top-notch wedding planners in Bangalore who understand
          your financial constraints, Zzeeh Weddings is the ideal choice.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
