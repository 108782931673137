import React from "react";
import { DotsGrid } from "../../assets/icons/Icons";
import vision1 from "../../assets/images/vision/vision1.webp";
import vision2 from "../../assets/images/vision/vision2.webp";
import vision3 from "../../assets/images/vision/vision3.webp";
import CTAButton from "../ui/CTAButton";

const Vision = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <section id="vision" className="relative px-4 py-20 lg:p-20 container">
      <div className="absolute inset-0 bg-dawn bg-cover bg-center bg-no-repeat z-0 rotate-180"></div>

      <div className="absolute left-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>

      <div className="absolute right-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>

      <div className="flex flex-col items-center space-y-10">
        <div className="text-center">
          <p className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage">
            Let's bring your <i className="text-gold font-gt-super">vision</i>{" "}
            to life!
          </p>
          <p className="text-purple text-sm lg:text-xl mt-3 md:w-[500px] lg:w-[600px]">
            Contact us to begin crafting an exceptional experience tailored to
            your budget. We're excited to work with you!
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <img
            data-aos="fade-right"
            data-aos-delay={200}
            src={vision1}
            alt="vision1"
          />
          <img
            data-aos="fade-right"
            data-aos-delay={400}
            src={vision2}
            alt="vision2"
          />
          <img
            data-aos="fade-right"
            data-aos-delay={600}
            src={vision3}
            alt="vision3"
          />
        </div>

        <div data-aos="fade-down" data-aos-delay={100}>
          <CTAButton
            name="Request a Callback"
            handleClick={() => setVisible(true)}
          />
        </div>
      </div>
    </section>
  );
};

export default Vision;
