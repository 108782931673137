import React, { useEffect, useState } from "react";
import { YellowStar } from "../../assets/icons/Icons";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import vision1 from "../../assets/images/vision/vision1.webp";
import vision2 from "../../assets/images/vision/vision2.webp";
import vision3 from "../../assets/images/vision/vision3.webp";
import gallery1 from "../../assets/images/gallery/gallery1.webp";
import gallery2 from "../../assets/images/gallery/gallery2.webp";
import gallery3 from "../../assets/images/gallery/gallery3.webp";
import gallery4 from "../../assets/images/gallery/gallery4.webp";
import gallery5 from "../../assets/images/gallery/gallery5.webp";
import gallery6 from "../../assets/images/gallery/gallery6.webp";

// Example image array, replace with actual image paths
const images1 = [vision3, vision1, vision3, vision2, gallery3];

const images2 = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery2,
];

const ZzeehGallery = ({ name }: { name: string }) => {
  const [velocity, setVelocity] = useState(30);

  useEffect(() => {
    const updateVelocity = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setVelocity(10); // Mobile
      } else if (width < 1024) {
        setVelocity(30); // Tablet
      } else {
        setVelocity(50); // Desktop
      }
    };

    updateVelocity();
    window.addEventListener("resize", updateVelocity);
    return () => window.removeEventListener("resize", updateVelocity);
  }, []);

  return (
    <section id="zzeeh-gallery" className="py-10 container">
      <div className="flex flex-col items-center space-y-10">
        <div className="flex flex-col items-center space-y-3">
          <div className="flex flex-row items-center space-x-5">
            <YellowStar />
            <p className="text-3xl lg:text-[40px] font-made-mirage font-medium">
              {name} <i className="text-gold">Gallery</i>
            </p>
            <YellowStar />
          </div>
          <p className="text-purple text-sm lg:text-xl text-center">
            Explore our stunning creations and memorable moments!
          </p>
        </div>

        <div>
          {/* Top scrolling div - right to left */}
          <div className="relative w-full overflow-hidden mb-5">
            <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="relative z-10">
              <Marquee
                scatterRandomly={false}
                resetAfterTries={5}
                onInit={() => {}}
                onFinish={() => {}}
                velocity={velocity}
                direction="rtl"
              >
                {times(10, Number).map((id) => (
                  <div key={`marquee-top-${id}`} className="mx-2">
                    <img
                      src={images1[id % images1.length]}
                      alt={`Gallery ${id}`}
                      className="w-96 h-64 object-cover rounded-lg"
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>

          {/* Bottom scrolling div - left to right */}
          <div className="relative w-full overflow-hidden">
            <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="relative z-10">
              <Marquee
                scatterRandomly={false}
                resetAfterTries={5}
                onInit={() => {}}
                onFinish={() => {}}
                velocity={velocity}
                direction="ltr"
              >
                {times(10, Number).map((id) => (
                  <div key={`marquee-bottom-${id}`} className="mx-2">
                    <img
                      src={images2[id % images2.length]}
                      alt={`Gallery ${id}`}
                      className="w-96 h-64 object-cover rounded-lg"
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>

        {/* <div data-aos="fade-down" data-aos-delay={50}>
          <Button
            color="light"
            className="border-dark hover:!bg-dark hover:!text-white"
            onClick={() => navigate("/gallery")}
          >
            <span className="!text-lg !font-medium">View gallery {`->`}</span>
          </Button>
        </div> */}
      </div>
    </section>
  );
};

export default ZzeehGallery;
