import React from "react";
import { servingQuality } from "../../assets/data/servingQuality";
import CTAButton from "../ui/CTAButton";
import Stats from "../ui/Stats";
import { Dawn } from "../../assets/icons/Icons";
import award1 from "../../assets/images/awards/award1.webp";
import award2 from "../../assets/images/awards/award2.webp";
import award3 from "../../assets/images/awards/award3.webp";

const ServingQuality = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <section
      id="serving-quality"
      className="relative z-10 px-4 py-10 container"
    >
      <div className="absolute inset-0 bg-globe bg-cover bg-center bg-no-repeat z-0"></div>

      <div className="relative z-10 flex flex-col items-center space-y-10">
        <p className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage text-center">
          Serving quality for{" "}
          <i className="text-gold font-gt-super">18 years</i> now!
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {servingQuality.map((item, index) => (
            <Stats
              key={index}
              Icon={item.icon}
              value={item.value}
              name={item.name}
            />
          ))}
        </div>

        <p className="text-purple lg:text-xl md:w-[500px] lg:w-[600px] text-center">
          We are meticulous and observant ensuring nothing is missing and all
          the items on your list have been checked!
        </p>

        <div className="flex flex-row justify-center items-center space-x-4">
          <Dawn />
          <p className="text-gold text-sm md:text-base font-medium uppercase text-center">
            Awards and recognitions
          </p>
          <Dawn />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-10">
          <div className="flex flex-col items-center text-center">
            <img
              data-aos="zoom-out"
              data-aos-delay={300}
              src={award1}
              alt="Spotlight Awards 2024"
              className="w-full h-auto lg:w-auto lg:h-full object-contain"
            />
            <p className="text-lg font-made-mirage font-medium mt-4">
              Spotlight Awards 2024
            </p>
            <p className="text-sm text-purple mt-1">Best Production House</p>
          </div>

          <div className="flex flex-col items-center text-center">
            <img
              data-aos="zoom-out"
              data-aos-delay={300}
              src={award2}
              alt="India Business Awards 2018"
              className="w-full h-auto lg:w-auto lg:h-full object-contain"
            />
            <p className="text-lg font-made-mirage font-medium mt-4">
              India Business Awards 2018
            </p>
            <p className="text-sm text-purple mt-1">
              Best Wedding Planner (South India)
            </p>
          </div>

          <div className="flex flex-col items-center text-center">
            <img
              data-aos="zoom-out"
              data-aos-delay={300}
              src={award3}
              alt="The Bizz Awards"
              className="w-full h-auto lg:w-auto lg:h-full object-contain"
            />
            <p className="text-lg font-made-mirage font-medium mt-4">
              The Bizz Awards
            </p>
            <p className="text-sm text-purple mt-1">
              Business Excellence Certificate
            </p>
          </div>
        </div>

        <div data-aos="fade-down" data-aos-delay={50}>
          <CTAButton
            name="Book Your Dream Wedding"
            handleClick={() => setVisible(true)}
          />
        </div>
      </div>
    </section>
  );
};

export default ServingQuality;
