import React, { useEffect, useRef, useState } from "react";
import { DotsGrid } from "../../assets/icons/Icons";
import { Carousel } from "flowbite-react";

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const WeddingVideos = () => {
  const [playing, setPlaying] = useState(false);
  const videoRefs = useRef<(HTMLIFrameElement | null)[]>([]);
  const carouselRef = useRef<HTMLDivElement | null>(null); // Fix for missing carouselRef

  // Load YouTube Iframe API script
  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    if (firstScriptTag) {
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }

    // Declare the onYouTubeIframeAPIReady function on the window object
    window.onYouTubeIframeAPIReady = () => {
      videoRefs.current.forEach((iframe, index) => {
        if (iframe) {
          new window.YT.Player(iframe, {
            events: {
              onStateChange: (event: any) =>
                handlePlayerStateChange(event, index),
            },
          });
        }
      });
    };
  }, []);

  const handlePlayerStateChange = (event: any, index: number) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setPlaying(true);
    } else if (
      event.data === window.YT.PlayerState.PAUSED ||
      event.data === window.YT.PlayerState.ENDED
    ) {
      setPlaying(false);
    }
  };

  return (
    <section id="vision" className="relative px-4 py-20 lg:p-20 container">
      <div className="absolute left-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>
      <div className="absolute right-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>
      <div className="flex flex-col items-center space-y-10">
        <div className="text-center">
          <p className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage">
            Explore Our <i className="text-gold font-gt-super">Wedding </i>
            Styles!
          </p>
          <p className="text-purple text-sm lg:text-xl mt-3 md:w-[500px] lg:w-[600px]">
            Discover the perfect style for your big day. Watch our samples
            below!
          </p>
        </div>
      </div>

      <div className="h-56 md:h-96 xl:h-[600px] mt-10" ref={carouselRef}>
        <Carousel slide={!playing}>
          <iframe
            ref={(el) => (videoRefs.current[0] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/uYj5dgb3_ok?enablejsapi=1" // enable JS API
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[1] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/cbpIH_CkykM?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[2] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/jV3iKj4ilNs?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[3] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/_zWxJi6Ie5Y?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Carousel>
      </div>
    </section>
  );
};

export default WeddingVideos;
