import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "what-makes-zzeeh-weddings-top-planner",
      label: "What Makes Zzeeh Weddings One of the Top Planners in Bangalore?",
    },
    {
      id: "core-services-offered",
      label: "Core Services Offered by Zzeeh Weddings",
    },
    {
      id: "innovative-wedding-themes",
      label: "Innovative Wedding Themes and Designs by Zzeeh Weddings",
    },
    {
      id: "budget-friendly-grand-celebrations",
      label:
        "Zzeeh Weddings: Perfect for Budget-Friendly and Grand Celebrations",
    },
    {
      id: "top-wedding-venues",
      label: "Top Wedding Venues in Bangalore Recommended by Zzeeh Weddings",
    },
    {
      id: "success-stories",
      label: "Success Stories: Real Weddings Planned by Zzeeh Weddings",
    },
    {
      id: "destination-weddings",
      label: "Why Zzeeh Weddings is Ideal for Destination Weddings",
    },
    // { id: "client-testimonials", label: "Client Testimonials and Reviews" },
    {
      id: "contact-zzeeh",
      label: "How to Get in Touch with Zzeeh Weddings",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Professionalism & Experience",
      body: "Zzeeh Weddings boasts years of experience in the wedding planning industry, making them experts at handling everything from intimate ceremonies to grand celebrations. Their team works with precision and professionalism, ensuring that every aspect of the wedding is flawlessly executed.",
    },
    {
      header: "Personalized Wedding Planning",
      body: "One of Zzeeh's standout features is their commitment to creating unique weddings that reflect the couple’s personalities and preferences. They customize each event, ensuring that the wedding is tailored to the couple’s vision, whether it's a traditional, contemporary, or themed celebration.",
    },
    {
      header: "Diverse Range of Services",
      body: "Zzeeh offers a wide range of services, from venue selection and decor to managing vendors and handling logistics. They provide end-to-end wedding planning, which makes them a one-stop solution for couples looking for hassle-free wedding coordination.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection & Decor",
      body: "Zzeeh Weddings has strong connections with some of the best wedding venues in Bangalore, helping couples find the perfect setting for their big day. Their decor team is skilled at transforming venues to match the desired theme and ambiance, ensuring that every wedding looks spectacular.",
    },
    {
      header: "Vendor Management",
      body: "From caterers to photographers, Zzeeh has an extensive network of reliable vendors. They take the burden off the couple by managing all vendor interactions, ensuring quality services at competitive rates.",
    },
    {
      header: "Budget Management",
      body: "Whether you’re planning a luxurious wedding or one on a tight budget, Zzeeh excels at managing costs effectively. They provide detailed budget breakdowns and ensure that every penny is spent wisely without compromising on the quality of the event.",
    },
    {
      header: "Wedding Coordination and Logistics",
      body: "Zzeeh provides day-of coordination, ensuring that the wedding runs smoothly. They manage timelines, coordinate with vendors, and handle any last-minute issues, allowing the couple to enjoy their day without stress.",
    },
  ];

  const list3 = [
    {
      header: "Traditional Indian Weddings",
      body: "Zzeeh Weddings is known for planning traditional Indian weddings that honor cultural customs while infusing modern elements for a personalized touch.",
    },
    {
      header: "Modern & Minimalist Weddings",
      body: "For couples seeking a chic, minimalist wedding, Zzeeh offers elegant decor solutions that focus on simplicity and sophistication.",
    },
    {
      header: "Themed & Destination Weddings",
      body: "If you’re looking for a unique wedding theme or planning a destination wedding, Zzeeh specializes in creative themes, such as fairytale weddings, bohemian setups, and more, ensuring your event stands out.",
    },
  ];

  const list4 = [
    {
      header: "How Zzeeh Weddings Handles Budget Weddings",
      body: "Zzeeh Weddings is well-known for its ability to create stunning weddings on a budget. They focus on prioritizing expenses, negotiating with vendors, and coming up with creative solutions to make the most of the budget while ensuring the couple's vision is realized.",
    },
    {
      header: "Planning Extravagant Weddings",
      body: "For those seeking a lavish affair, Zzeeh is experienced in managing large-scale weddings with luxurious venues, elaborate decor, and high-end vendors, ensuring an opulent celebration.",
    },
  ];

  const list5 = [
    {
      header: "Outdoor Garden Venues",
      body: "Beautiful garden spaces perfect for day or evening weddings.",
    },
    {
      header: "Luxury Hotels and Resorts",
      body: "For grand celebrations with full-service packages.",
    },
    {
      header: "Intimate Wedding Spaces",
      body: "Cozy, smaller venues that offer a personal touch for intimate weddings.",
    },
  ];

  const list6 = [
    {
      header: "Case Study 1: A Lavish Palace Wedding",
      body: "A traditional Indian palace wedding with royal decor, intricate floral arrangements, and cultural ceremonies.",
    },
    {
      header: "Case Study 2: An Intimate Garden Wedding",
      body: "A simple yet elegant garden wedding that focused on nature-inspired decor, personalized vows, and close family members.",
    },
    {
      header: "Case Study 3: A Destination Wedding in Goa",
      body: "An exotic beach wedding with a stunning sunset ceremony, complete with live music and local Goan delicacies.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Top Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/uYj5dgb3_ok?si=TY7yUjbKmegXRvkt"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a wedding is a monumental task that requires creativity,
        precision, and the ability to handle multiple aspects of the event
        seamlessly. In a bustling city like Bangalore, with its vibrant mix of
        traditions and modern trends, hiring a wedding planner can make all the
        difference. Among the best in the industry is{" "}
        <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
          Zzeeh Weddings
        </a>
        , a highly reputed wedding planning company known for its ability to
        turn dreams into reality, no matter the budget or style of the event.
      </p>
      <p className="!mt-3">
        In this blog, we will explore why Zzeeh Weddings stands out as one of
        the top wedding planners in Bangalore, offering a range of services
        tailored to create unforgettable weddings.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Organizing a wedding involves numerous elements, from selecting the
          perfect venue to curating a memorable theme, ensuring that every
          detail is executed with precision. While this can be overwhelming for
          couples, wedding planners like{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          in Bangalore take on the responsibility of turning dreams into
          reality, ensuring a stress-free experience for the bride and groom.
        </p>
        <p>
          With a focus on personalization, efficiency, and creativity, Zzeeh
          Weddings has become one of the top wedding planners in Bangalore,
          catering to a wide array of wedding styles and budgets.
        </p>
      </section>

      <section id="what-makes-zzeeh-weddings-top-planner" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          What Makes Zzeeh Weddings One of the Top Planners in Bangalore?
        </h1>
        <p>
          There are several key factors that distinguish Zzeeh Weddings as one
          of the leading wedding planners in the city:
        </p>
        <DynamicList newLine listType="list-decimal" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/the-wedding-planner-budget.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="core-services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Core Services Offered by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-decimal" data={list2} />
      </section>

      <section id="innovative-wedding-themes" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Innovative Wedding Themes and Designs by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-decimal" data={list3} />
      </section>

      <section id="budget-friendly-grand-celebrations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: Perfect for Budget-Friendly and Grand Celebrations
        </h1>
        <DynamicList newLine listType="list-decimal" data={list4} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/simple-marriages-decoration.webp"
        alt="budget-decor-ideas"
      />

      <section id="top-wedding-venues" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues in Bangalore Recommended by Zzeeh Weddings
        </h3>
        <p>
          Some of the popular venues that{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          recommends for couples include:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <section id="success-stories" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Success Stories: Real Weddings Planned by Zzeeh Weddings
        </h3>
        <DynamicList newLine listType="list-decimal" data={list6} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-reception-decor-zzeehwedding.webp"
        alt="success-stories"
      />

      <section id="destination-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings is Ideal for Destination Weddings
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          also excels in planning destination weddings. Their team handles
          everything from venue scouting to managing travel logistics and
          coordinating with local vendors, making them a go-to choice for
          couples looking for a destination wedding near Bangalore or at exotic
          international locations.
        </p>
      </section>

      {/* <section id="client-testimonials" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials and Reviews
        </h1>
        <p>
          Zzeeh Weddings is praised by clients for their dedication, creativity,
          and attention to detail. Many couples commend their ability to bring
          their dream weddings to life, offering stress-free planning and
          flawless execution.
        </p>
      </section> */}

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          With their wealth of experience, creativity, and professionalism,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has earned its reputation as one of the top wedding planners in
          Bangalore. Whether you’re planning a small, intimate affair or a
          large, luxurious celebration, their team ensures every detail is
          meticulously handled. Reach out to Zzeeh Weddings today to start
          planning your dream wedding!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
